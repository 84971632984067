import React from "react";
import { useEffect } from "react";
import "./tripDetails.css";
import { Link, useLocation } from "react-router-dom";
import Photo from "../../assets/images/tripdetail_gallery.png";
import sendImage from "../../assets/images/Group 289070.png";
import callImage from "../../assets/images/Group 289071.png";
import wtspImage from "../../assets/images/Group 289072.png";
import vector from "../../assets/images/iconeeeeeeee.jpg";
import sendEnquiry from "../../assets/images/Send Enquiry.png";
import callExpert from "../../assets/images/Call expert.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import wtsp_icon from "../../assets/images/Group 288953.png";
import "react-tabs/style/react-tabs.css";
import picuplocation from "../../assets/images/pickuplocation.png";
import picdroplocation from "../../assets/images/picdroplocation.png";
import besttime from "../../assets/images/besttime.png";
import { FaCalendarAlt } from "react-icons/fa";
import { Navigation, Pagination, Autoplay, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import nuberplace from "../../assets/images/numberplaces.jpg";
import { useState, useRef } from "react";
import "swiper/css";
import { UseSelector, useSelector } from "react-redux";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import SwiperCore from "swiper";
import { TbCheckbox } from "react-icons/tb";
import { YouTubePlayer } from "react-youtube";
import duration from "../../assets/images/duration-icon.jpg";
import line from "../../assets/images/Line 30.jpg";
import rupesicon from "../../assets/images/PRICE-ICON.jpg";
import heart from "../../assets/images/Wishlist-icon 2.png";
import share from "../../assets/images/Share-icon_.png";
import Button from "react-bootstrap/Button";
import { LiaGreaterThanSolid } from "react-icons/lia";
import { LiaLessThanSolid } from "react-icons/lia";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { Loader } from "../loader/Loader";
import redHeart from "../../assets/images/red-heart.png";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { RedirectUrlAfterLogin } from "../repeatComponent/RedirectUrlAfterLogin";
import Lightbox from "yet-another-react-lightbox";
import {
  FaUtensils,
  FaCoffee,
  FaBicycle,
  FaFire,
  FaMusic,
  FaCookie,
  FaHorse,
  FaLandmark,
  FaFilm,
  FaCar,
  FaWater,
  FaQuestionCircle,
  FaRocket,
} from "react-icons/fa";
import ReactDOM from "react-dom";
import { useAppDispatch } from "../../hook";
import { log } from "console";

function TripDetails() {
  const closeBtnRef: any = useRef(null);
  const [selectedOption, setSelectedOption] = useState<any>("");
  const location = useLocation();
  const id = location?.state;
  const { id: newID } = useParams();
  // const { id } = useLocation();

  const storedId = id ?? "";
  const [tourPdf, setTourPdf] = useState<any | null>(null);

  const [slides, setSlides] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [data, setData] = useState<any>();
  const [PackegPrice, setPackegPrice] = useState<any>();
  const [durations, setDurations] = useState<any>();
  const [tour, setTour] = useState<any>();
  const [backgroundImageUrl, setBackgroundImageUrl] = useState<any>("");
  const [coupon, setCoupon] = useState<any>();
  const [selectedMonth, setSelectedMonth] = useState<any>();
  const [batch, setBatch] = useState<any>();
  const [defaultBatch, setDefaultBatch] = useState<any>();
  const [boardingPointCity, setBoardingPointCity] = useState<any>([]);
  const [boardingPointTypeObj, setBoardingPointTypeObj] = useState<any>([]);
  const [load, setLoad] = useState<boolean>(true);
  const [swiper, setSwiper] = useState<any>(null);
  const [swiper1, setSwiper1] = useState<any>(null);
  const [swiper2, setSwiper2] = useState<any>(null);
  const [swiper3, setSwiper3] = useState<any>(null);
  const [swiper7, setSwiper7] = useState<any>(null);
  const [isPaused, setIsPaused] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showMenuButton, setShowMenuButton] = useState(true);
  const [boardingPoints, setBoardingPoints] = useState<any>([]);
  const [tour_name, setTour_name] = useState("");
  const [tour_iid, setTour_id] = useState("");

  const [destination, setDestionation] = useState("");
  const [destination_id, setDestionation_id] = useState("");
  const [tourType, setTourType] = useState<string>("");
  const userData: any = localStorage.getItem("userData");
  const parsedData = JSON.parse(userData);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [wishlisted, setWishlisted] = useState({ isWishlisted: false });
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [backgroundImage, setBackgroundImage] = useState(Photo);
  const [properties, setProperties] = useState([]);
  const [show, setShow] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const handleOpenPopup = () => setShowPopup(true);
  const handleClosePopup = () => setShowPopup(false);

  // Detect if the device is mobile
  useEffect(() => {
    const checkIfMobile = () => {
      const mobileDevices =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      setIsMobile(mobileDevices.test(navigator.userAgent));
    };
    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);
    return () => window.removeEventListener("resize", checkIfMobile);
  }, []);



  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );

  const navigate = useNavigate();

  let userId = parsedData?._id || "";
  const [calculationObj, setCalculationObj] = useState<any>({
    boardingType:
      boardingPointTypeObj.length &&
        Object.keys(JSON.parse(boardingPointTypeObj))?.length
        ? Object.keys(JSON.parse(boardingPointTypeObj))[0]
        : "",
    boardingPointPrice:
      boardingPointTypeObj.length &&
        Object.values(JSON.parse(boardingPointTypeObj))?.length
        ? Number(Object.values(JSON.parse(boardingPointTypeObj))[0])
        : 0,
    child_count: 0,
    child_price: 0,
    domesticorinternational: data?.domesticorInternational || "",
    finalPrice: 0,
    infant_count: 0,
    infant_price: 0,
    old_travel_no_of_traveler: 0,
    status: "active",
    tourDate: "",
    tour_id: data?._id,
    tour_type: data?.tourtype,
    travel_boarding_point: "",
    travel_date: "",
    travel_no_of_traveler: 0,
    travel_packageType_data: [
      {
        packType: "",
        packPrice: 0,
        type: "normal",
        persons: 0,
      },
    ],
    travel_roomSharing_data: [
      {
        roomType: "",
        roomPrice: 0,
        persons: 0,
      },
    ],
    user_id: parsedData?._id,
  });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const [renderPdf, setRenderPdf] = useState(false);

  const handleRenderPdf = () => {
    setRenderPdf(true);
  };
  const Toast = Swal.mixin({
    toast: true,
    position: "bottom",
    iconColor: "white",
    customClass: {
      popup: "colored-toast",
    },
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
  });

  const swiperParams5 = {
    slidesPerView: 1,
    spaceBetween: 0,
    breakpoints: {
      320: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      425: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      480: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 5,
        spaceBetween: 30,
      },
    },
  };

  const swiperParams7 = {
    slidesPerView: 1,
    spaceBetween: 0,
    autoplay: {
      delay: 1000, // Adjust the delay as needed
      disableOnInteraction: false, // Allow autoplay to continue even when user interacts with the swiper
    },
    breakpoints: {
      320: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      425: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      480: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 0,
      },
    },
  };

  const swiperParamsPhoto = {
    slidesPerView: 1,
    spaceBetween: 100,

    breakpoints: {
      420: {
        slidesPerview: 1,
        spaceBetween: 50,
      },
      576: {
        slidesPerView: 1,
        spaceBetween: 50,
      },
      768: {
        slidesPerView: 1,
        spaceBetween: 55,
      },
      992: {
        slidesPerView: 2,
        spaceBetween: 80,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 45,
      },
      1400: {
        slidesPerView: 3,
        spaceBetween: 85,
      },
    },
  };

  function extractYouTubeID(url: any) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|watch\?.+&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  }

  const handleLoginpage = () => {
    Swal.fire({
      icon: "info",
      title: "Please Log In First",
      text: "You need to log in before booking.",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.setItem("tripId", id);
        storeRedirectUrl();
        navigate("/login");
      }
    });
  };

  const swiperParamsPhoto2 = {
    slidesPerView: 1,
    spaceBetween: 0,

    breakpoints: {
      420: {
        slidesPerview: 1,
        spaceBetween: 0,
      },
      576: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      768: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      992: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 12,
      },
      1400: {
        slidesPerView: 4,
        spaceBetween: 15,
      },
    },
  };
  let videoElement: YouTubePlayer = null;

  const totalAdults = () => {
    let totalAdults = 0;

    if (
      calculationObj.travel_packageType_data &&
      calculationObj.travel_packageType_data.length
    ) {
      totalAdults = calculationObj.travel_packageType_data.reduce(
        (sum: number, item: any) => sum + item.persons,
        0
      );
    }
    const totalKids = calculationObj.child_count + calculationObj.infant_count;
    return totalAdults + totalKids;
  };
  const handleImageClick = () => {
    setIsModalOpen(true);
  };
  const ImageClick = (index: any) => {
    // setIsModalOpen(true);
    setActiveIndex(index);
    setShowModal(true);
  };
  // Prepare the slides for the lightbox
  React.useEffect(() => {
    if (data?.multiple_image && data.multiple_image.length > 0) {
      const imageSlides = data.multiple_image.map((image: any) => ({
        src: `${process.env.REACT_APP_API_URL}/${image.src}`,
      }));
      setSlides(imageSlides);
    }
  }, [data]);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const errorToast = (text: any) => {
    Toast.fire({
      icon: "error",
      iconColor: "white",
      title: text,
    });
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const storeRedirectUrl = () => {
    const currentUrl = window.location.pathname;
    localStorage.setItem("redirectUrl", currentUrl);
  };
  const currentUrl = window.location.pathname;

  localStorage.setItem("redirectUrl", currentUrl);
  const totalRoomSharingPersons = () => {
    if (
      calculationObj.travel_roomSharing_data &&
      calculationObj.travel_roomSharing_data.length
    ) {
      return calculationObj.travel_roomSharing_data.reduce(
        (sum: number, item: any) => sum + item.persons,
        0
      );
    } else {
      return 0;
    }
  };

  const getPropertyIcon = (property: string) => {
    const iconStyle = { color: "black" }; // Define the icon style

    switch (property.toLowerCase()) {
      case "lunch":
      case "dinner":
        return <FaUtensils style={iconStyle} />;
      case "breakfast":
        return <FaCoffee style={iconStyle} />;
      case "camel_ride":
        return <FaHorse style={iconStyle} />;
      case "movie_screening":
        return <FaFilm style={iconStyle} />;
      case "jeep_safari":
        return <FaCar style={iconStyle} />;
      case "bike_rent":
        return <FaBicycle style={iconStyle} />;
      case "bonfire":
        return <FaFire style={iconStyle} />;
      case "dj_night":
      case "live_music":
        return <FaMusic style={iconStyle} />;
      case "evening_snacks":
        return <FaCookie style={iconStyle} />;
      case "monument_fee":
        return <FaLandmark style={iconStyle} />;
      case "riverrafting":
      case "water_activities":
        return <FaWater style={iconStyle} />;
      case "paragliding":
        return <FaRocket style={iconStyle} />;
      default:
        return <FaQuestionCircle style={iconStyle} />;
    }
  };
  function sharePageDetails() {
    var pageUrl = window.location.href;

    window.open(
      "https://twitter.com/intent/tweet?url=" + encodeURIComponent(pageUrl)
    );
  }
  const showLoader = (text: string) => {
    Swal.fire({
      text: text,
      allowOutsideClick: false,
      showConfirmButton: false,
      heightAuto: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  };
  const [mobileError, setMobileError] = useState("");
  const [emailError, setEmailError] = useState("");
  const dispatch = useAppDispatch();
  const [otpVisible, setOtpVisible] = useState(false);
  const [otpData, setOtpData] = useState();
  const [menuData, setMenuData] = useState<any>([]);
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [formData1, setFormData1] = useState({
    name: "",
    numberOfTraveller: "",
    mobile: "",
    email: "",
    traveler_month: "",
    otp: "",
    tour_name: "",
    destination_id: data?.menumaster_id,
    destination: data?.menumaster_id.menu,
  });
  const [formData2, setFormData2] = useState({
    name: "",
    numberOfTraveller: "",
    mobile: "",
    email: "",
    traveler_month: "",
    otp: "",
    tour_name: "",
    destination_id: data?.menumaster_id,
    destination: data?.menumaster_id.menu,
  });
  const [formData3, setFormData3] = useState({
    name: "",
    numberOfTraveller: "",
    mobile: "",
    email: "",
    traveler_month: "",
    otp: "",
    tour_name: "",
    destination_id: data?.menumaster_id,
    destination: data?.menumaster_id.menu,
  });

  const resetFormData = () => {
    setFormData1({
      name: "",
      numberOfTraveller: "",
      mobile: "",
      email: "",
      traveler_month: "",
      otp: "",
      tour_name: "",
      destination_id: data?.menumaster_id,
      destination: data?.menumaster_id.menu,
    }),
      setFormData2({
        name: "",
        numberOfTraveller: "",
        mobile: "",
        email: "",
        traveler_month: "",
        otp: "",
        tour_name: "",
        destination_id: data?.menumaster_id,
        destination: data?.menumaster_id.menu,
      }),
      setFormData3({
        name: "",
        numberOfTraveller: "",
        mobile: "",
        email: "",
        traveler_month: "",
        otp: "",
        tour_name: "",
        destination_id: data?.menumaster_id,
        destination: data?.menumaster_id.menu,
      })
  }
  const handleChange1 = (e: any) => {
    let { name, value } = e.target;

    setFormData1({
      ...formData1,
      [name]: value,
    });

    const mobilePattern = /^[0-9]{10}$/;
    if (name === "mobile") {
      if (!mobilePattern.test(value)) {
        setMobileError("Please enter a valid 10-digit mobile number");
      } else {
        setMobileError("");
      }
    }
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (name === "email") {
      if (!emailPattern.test(value)) {
        setEmailError("Please enter a valid email address");
      } else {
        setEmailError("");
      }
    }

  };

  const handleChange2 = (e: any) => {
    let { name, value } = e.target;

    setFormData2({
      ...formData2,
      [name]: value,
    });

    const mobilePattern = /^[0-9]{10}$/;
    if (name === "mobile") {
      if (!mobilePattern.test(value)) {
        setMobileError("Please enter a valid 10-digit mobile number");
      } else {
        setMobileError("");
      }
    }
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (name === "email") {
      if (!emailPattern.test(value)) {
        setEmailError("Please enter a valid email address");
      } else {
        setEmailError("");
      }
    }

  };

  const handleChange3 = (e: any) => {
    let { name, value } = e.target;

    setFormData3({
      ...formData3,
      [name]: value,
    });

    const mobilePattern = /^[0-9]{10}$/;
    if (name === "mobile") {
      if (!mobilePattern.test(value)) {
        setMobileError("Please enter a valid 10-digit mobile number");
      } else {
        setMobileError("");
      }
    }
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (name === "email") {
      if (!emailPattern.test(value)) {
        setEmailError("Please enter a valid email address");
      } else {
        setEmailError("");
      }
    }

  };

  const isFormValid = () => {
    return (
      formData2.name &&
      formData2.numberOfTraveller &&
      formData2.email &&
      formData2.mobile.length === 10 &&
      formData2.otp.trim() !== "" // Ensure OTP is not empty
    );
  };

  const isFormValid2 = () => {
    return (
      formData1.name &&
      formData1.numberOfTraveller &&
      formData1.email &&
      formData1.mobile.length === 10 &&
      formData1.otp.trim() !== "" // Ensure OTP is not empty
    );
  };
  const isFormValid3 = () => {
    return (
      formData3.name &&
      formData3.numberOfTraveller &&
      formData3.email &&
      formData3.mobile.length === 10 &&
      formData3.otp.trim() !== "" // Ensure OTP is not empty
    );
  };
  const handleVerifyOtp = async (value: any, check: number) => {
    showLoader("Sending enquiry Please Wait");

    if (value) {
      const response = await verifyOtp(value, check);

      if (response.success) {
        Swal.fire({
          title: 'Success!',
          text: 'OTP verified and enquiry sent successfully',
          icon: 'success',
          confirmButtonText: 'OK',
        }).then(() => {
          resetFormData();
        });

      } else {
        Swal.fire({
          title: 'Invalid OTP!',
          text: response.message || 'The OTP you entered is incorrect. Please try again.',
          icon: 'error',
          confirmButtonText: 'Retry',
        });
      }
    } else {
      Swal.fire({
        title: 'OTP Required',
        text: 'Please enter the One-Time Password to proceed.',
        icon: 'warning',
        confirmButtonText: 'OK',
        timer: 5000,  // 5000 milliseconds = 5 seconds
        willClose: () => {
          console.log("Alert closed after 5 seconds.");
        }
      });


    }
  };
  const sendOtpEnquiry = async (mobile: string) => {
    showLoader("Sending OTP Please Wait");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/sendOtpForEnquiry`,
        { mobile }
      );
      console.log("OTP Sent Successfully:", response.data);
      setOtpData(response.data.otp._id);

    } catch (error) {
      console.error("Error sending OTP:", error);
      setMobileError("Failed to send OTP. Please try again.");
    }
  };
  const verifyOtp = async (otp: string, check: number): Promise<{ success: boolean; message: string }> => {
    let allValue = check === 1 ? formData1 : check === 2 ? formData2 : formData3
    showLoader("Sending enquiry Please Wait");

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/verifyOtpForEnquiry?id=${otpData}`,
        {
          otp: allValue.otp,
          name: allValue.name,
          destination: tour_iid,
          numberOfTraveller: allValue.numberOfTraveller,
          mobile: allValue.mobile,
          email: allValue.email,
          traveler_month: allValue.traveler_month,
          tour_name: tour_name,
          // destination_id: destination_id,

        }
      );

      // Assuming the API response contains `success` and `message`
      return response.data;
    } catch (error) {
      // Handle the error and return a failure response
      return {
        success: false,
        message: 'Failed to verify OTP. Please try again.',
      };
    }
  };
  const sendOtp = async (value: any) => {
    if (value.length === 10) {
      try {
        await sendOtpEnquiry(value);

        // Show success message using SweetAlert
        Swal.fire({
          title: 'OTP Sent!',
          text: `An OTP has been sent to your mobile number ${value}.`,
          icon: 'success',
          confirmButtonText: 'OK',
        });

        setOtpVisible(true);
      }
      catch (error) {
        console.error("Error in sendOtp function:", error);

        // Show error message using SweetAlert
        Swal.fire({
          title: 'Error',
          text: 'Failed to send OTP. Please try again later.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    } else {
      setMobileError("Please enter a valid mobile number");
      setOtpVisible(false);

      // Show validation error using SweetAlert
      Swal.fire({
        title: 'Invalid Number',
        text: 'Please enter a valid 10-digit mobile number.',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
    }
  };





  const totalAmount = () => {
    let totalPackageAmount = calculationObj.travel_packageType_data.reduce(
      (sum: number, item: any) => sum + item.persons * item.packPrice,
      0
    );
    let totalRoomAmount = calculationObj.travel_roomSharing_data.reduce(
      (sum: number, item: any) => sum + item.persons * item.roomPrice,
      0
    );
    let totalKidsAmount =
      calculationObj.child_count * calculationObj.child_price;
    let totalInfantAmount =
      calculationObj.infant_count * calculationObj.infant_price;
    let boardingPointPrice = boardingPointTypeObj.length
      ? Object.values(JSON.parse(boardingPointTypeObj))[0]
      : 0;
    return (
      totalPackageAmount +
      totalRoomAmount +
      totalKidsAmount +
      totalInfantAmount +
      Number(boardingPointPrice) * totalAdults() || 0
    );
  };

  const incrementPackageTypeCount = (index: number) => {
    calculationObj.travel_packageType_data[index].persons =
      (calculationObj.travel_packageType_data[index]?.persons || 0) + 1;
    setCalculationObj({
      ...calculationObj,
      travel_packageType_data: calculationObj.travel_packageType_data,
    });
  };

  const decrementPackageTypeCount = (index: number) => {
    if (calculationObj.travel_packageType_data[index]?.persons > 0) {
      calculationObj.travel_packageType_data[index].persons =
        (calculationObj.travel_packageType_data[index]?.persons || 0) - 1;
      setCalculationObj({
        ...calculationObj,
        travel_packageType_data: calculationObj.travel_packageType_data,
      });
    }
  };

  const incrementRoomTypeCount = (index: number) => {
    if (
      totalAdults() -
      calculationObj?.child_count -
      calculationObj?.infant_count >
      totalRoomSharingPersons()
    ) {
      calculationObj.travel_roomSharing_data[index].persons =
        (calculationObj.travel_roomSharing_data[index]?.persons || 0) + 1;
      setCalculationObj({
        ...calculationObj,
        travel_roomSharing_data: calculationObj.travel_roomSharing_data,
      });
    }
  };

  const decrementRoomTypeCount = (index: number) => {
    if (calculationObj.travel_roomSharing_data[index]?.persons > 0) {
      calculationObj.travel_roomSharing_data[index].persons =
        (calculationObj.travel_roomSharing_data[index]?.persons || 0) - 1;
      setCalculationObj({
        ...calculationObj,
        travel_roomSharing_data: calculationObj.travel_roomSharing_data,
      });
    }
  };
  const proceedToCheckout = async () => {
    if (!boardingPointCity || boardingPointCity.length === 0) {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: "Please select a boarding city.",
      });
      return;
    }

    if (!boardingPointTypeObj || boardingPointTypeObj.length === 0) {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: "Please select a boarding type.",
      });
      return;
    }

    let boardingPointPrice = boardingPointTypeObj.length
      ? Object.values(JSON.parse(boardingPointTypeObj))[0]
      : 0;
    let boardingType = boardingPointTypeObj.length
      ? Object.keys(JSON.parse(boardingPointTypeObj))[0]
      : "";

    let checkoutObj = {
      ...calculationObj,
      boardingType: boardingType,
      bordingprice: boardingPointPrice,
      domesticorinternational: data?.domesticorInternational || "",
      finalPrice: totalAmount(),
      old_travel_no_of_traveler: totalAdults(),
      status: "active",
      tourDate: JSON.parse(selectedOption)?._id,
      tour_id: data?._id,
      tour_type: data?.tourtype,
      travel_boarding_point: boardingPoints.find(
        (o: { boarding: string }) =>
          o.boarding?.toLowerCase() === boardingPointCity?.toLowerCase()
      )?._id,
      travel_date: JSON.parse(selectedOption)?._id,
      travel_no_of_traveler: totalAdults(),
      user_id: parsedData?._id,
    };

    try {
      setLoad(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/checkout-tour-new`,
        checkoutObj
      );
      if (response?.data?.success) {
        localStorage.setItem("checkoutResponse", JSON.stringify(response.data));
        closeBtnRef.current.click();
        const id = response?.data?.objId;

        navigate(`/checkout`, {
          state: id,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Something went wrong during checkout. Please try again.",
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while processing your request. Please try again later.",
      });
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    if (selectedOption) {
      setCalculationObj({
        ...calculationObj,
        infant_price: JSON.parse(selectedOption)?.infant_price || 0,
        child_price: JSON.parse(selectedOption)?.child_price || 0,
        travel_packageType_data: JSON.parse(
          JSON.parse(selectedOption)?.packageType_price[0] || "[]"
        ).map((o: any) => ({ ...o, persons: 0 })),
        travel_roomSharing_data: JSON.parse(
          JSON.parse(selectedOption)?.roomSharing_price[0] || "[]"
        ).map((o: any) => ({ ...o, persons: 0 })),
      });
    }
  }, [selectedOption]);

  const addRemoveWishlist = async (_id: string) => {
    try {
      const dataFromLocalStorage = localStorage.getItem("userData");
      if (!dataFromLocalStorage) {
        Swal.fire({
          icon: "warning",
          title: "Please login first to add to wishlist",
          showConfirmButton: true,
        }).then(() => {
          navigate("/login");
        });
        return;
      }

      const parsedData = JSON.parse(dataFromLocalStorage);
      const url = `${process.env.REACT_APP_API_URL}/post-wishlist`;

      const requestData = {
        user_id: parsedData._id,
        tour_id: _id,
      };

      const response: any = await axios.put(url, requestData);

      if (response.status === 200) {
        // Toggle `isWishlisted` locally to update the icon immediately
        setWishlisted((prevWishlisted) => ({
          ...prevWishlisted,
          isWishlisted: !prevWishlisted.isWishlisted,
        }));

        // Display a message to the user based on the action
        Swal.fire({
          icon: wishlisted.isWishlisted ? "info" : "success",
          title: wishlisted.isWishlisted
            ? "Wishlist Removed"
            : "Added to Wishlist",
          showConfirmButton: true,
          // timer: 1500,
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  function capitalizeFirstLetter(str: any) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      autoplay: 1,
    },
  };

  useEffect(() => {
    if (videoElement) {
      const elapsed_seconds = videoElement.target.getCurrentTime();

      const elapsed_milliseconds = Math.floor(elapsed_seconds * 1000);
      const ms = elapsed_milliseconds % 1000;
      const min = Math.floor(elapsed_milliseconds / 60000);
      const seconds = Math.floor((elapsed_milliseconds - min * 60000) / 1000);

      const formattedCurrentTime =
        min.toString().padStart(2, "0") +
        ":" +
        seconds.toString().padStart(2, "0") +
        ":" +
        ms.toString().padStart(3, "0");

      if (isPaused) {
        videoElement.target.pauseVideo();
      } else {
        videoElement.target.playVideo();
      }
    }
  }, [isPaused, videoElement]);

  useEffect(() => {
    localStorage.setItem("id", storedId);
    const interval = setInterval(async () => {
      if (videoElement && videoElement.target.getCurrentTime() > 0) {
        const elapsed_seconds = videoElement.target.getCurrentTime();
        const elapsed_milliseconds = Math.floor(elapsed_seconds * 1000);
        const ms = elapsed_milliseconds % 1000;
        const min = Math.floor(elapsed_milliseconds / 60000);
        const seconds = Math.floor((elapsed_milliseconds - min * 60000) / 1000);

        const formattedCurrentTime =
          min.toString().padStart(2, "0") +
          ":" +
          seconds.toString().padStart(2, "0") +
          ":" +
          ms.toString().padStart(3, "0");

        if (videoElement.target.playerInfo.playerState === 1) {
        } else if (videoElement.target.playerInfo.playerState === 2) {
          console.log("the video is paused");
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    setTour_name(data?.title);
    setTour_id(data?._id)
    setDestionation(data?.menumaster_id?.menu);
    setDestionation_id(data?.menumaster_id._id);
  }, [data?.title]);

  useEffect(() => {
    if (data?.multiple_image && data.multiple_image.length > 0) {
      setBackgroundImage(
        `${process.env.REACT_APP_API_URL}/${data?.multiple_image[currentImageIndex]?.src}`
      );
    }
  }, [currentImageIndex, data]);

  SwiperCore.use([Navigation]);

  const handlePrevClick = () => {
    if (swiper !== null && swiper.slidePrev) {
      swiper.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (swiper !== null && swiper.slideNext) {
      swiper.slideNext();
    }
  };

  const handleNextClick1 = () => {
    if (swiper1 !== null && swiper1.slideNext) {
      swiper1.slideNext();
    }
  };

  const handleNextClick2 = () => {
    if (swiper2 !== null && swiper2.slideNext) {
      swiper2.slideNext();
    }
  };

  const handleNextClick3 = () => {
    if (swiper3 !== null && swiper3.slideNext) {
      swiper3.slideNext();
    }
  };




  useEffect(() => {
    fetchData();
    getAllBoardingPoints();
    coupons();
  }, [id, navigate]);
  const getAllBoardingPoints = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/get-all-boarding`
      );

      if (response?.data && response?.data.data.length) {
        setBoardingPoints(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const similarTrip = (_id: any) => {
    const id = _id._id;
    const slugUrl = _id?.title
      ?.toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/\//g, "-")
      .replace(/\((.*?)\)/g, "$1"); // Remove parentheses

    navigate(`/tripdetails/${slugUrl}`, {
      state: id,
    });
  };
  const fetchData = async () => {
    let sendID = newID || localStorage.getItem("tripId");
    try {
      setLoad(true);

      let queryString: any = "";
      if (userId) {
        queryString = `?user_id=${userId}`;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/get-tourBySlugURLWEB`,
        {
          slug_url: sendID,
        }
      );
      if (response?.data) {
        setData(response.data.getData);
        setPackegPrice(response.data);
        setWishlisted(response.data);
        setDurations(response.data);
        setTour(response?.data);

        // Extract banner image
        const imageUrl = response?.data?.getData?.banner_image[0]?.src;
        if (imageUrl) {
          setBackgroundImageUrl(`${process.env.REACT_APP_API_URL}/${imageUrl}`);
        }

        // Group batches by month
        const groupedBatch = groupByMonth(
          response?.data?.getData?.select_batch
        );
        setDefaultBatch(response?.data?.getData?.select_batch);
        setBatch(groupedBatch);
        setSelectedMonth(
          Object.keys(groupedBatch)?.length ? Object.keys(groupedBatch)[0] : ""
        );

        // Set tour type
        setTourType(response?.data?.getData?.tourtype);

        // Extract and set tour_pdf
        const tourPdf = response?.data?.getData?.tour_pdf;
        if (tourPdf) {
          setTourPdf(tourPdf); // Assuming you have a state setter for tour_pdf
        }
      }
      setLoad(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoad(false);
    }
  };

  const coupons = async () => {
    try {
      setLoad(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/get-all-active-coupon-by-tour-id/${id}`
      );
      setCoupon(response.data.data);

      setLoad(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleMonthClick = (month: any) => {
    setSelectedMonth(month);
  };
  const groupByMonth = (array: any) => {
    const grouped: any = {};
    const currentDate = new Date();
    array.forEach((obj: any) => {
      const startDate = new Date(obj.start_date);
      if (startDate > currentDate) {
        const month = startDate.toLocaleString("default", { month: "long" });
        const key: string = `${month}`;
        if (!grouped[key]) {
          grouped[key] = [];
        }
        grouped[key].push(obj);
      }
    });

    return grouped;
  };

  const getPDF = async (tId: any) => {
    let sendIteneray = data?._id || tId;
    try {
      setLoad(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/itinareryConvertePdf?tourId=${sendIteneray}`
      );
      if (response?.data) {
        const url = response.data.pdfUrl;
        // window.location.href = url;
        window.open(url, "_blank");
        setLoad(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoad(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    function handleScroll() {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      const scrollThreshold = 100;

      setShowMenuButton(scrollTop > scrollThreshold);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (batch && batch[selectedMonth]?.length) {
      const firstOption = JSON.stringify(batch[selectedMonth][0]);
      setSelectedOption(firstOption);
    }
  }, [batch, selectedMonth]);

  return (
    <>
      <RedirectUrlAfterLogin />
      {load ? <Loader /> : ""}
      <div
        className="Main_div"
        style={{ backgroundImage: `url(${backgroundImageUrl})` }}
      >
        <div className="container">
          <div className="grid-container grid_small">
            <div className="grid-item item1">
              <h4 className="fallhead">Fall in Love with </h4>
              <h2 className="kashmirhead">{data?.title}</h2>
            </div>
            <div className="grid-item item2">
              <div className="fallhead2 d-flex">
                {data?.whatsapp_number && (
                  <a
                    href={`https://api.whatsapp.com/send?phone=${data?.whatsapp_number}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>
                      <img
                        src={share}
                        className="img-fluid icon_bx"
                        alt="Share Icon"
                      />
                    </span>
                  </a>
                )}
                &nbsp;
                <span
                  onClick={() => addRemoveWishlist(data?._id)}
                  style={{ cursor: "pointer" }}
                >
                  {wishlisted?.isWishlisted ? (
                    <img
                      src={redHeart}
                      className="img-fluid icon_bx"
                      alt="Heart Icon"
                    />
                  ) : (
                    <img
                      src={heart}
                      className="img-fluid icon_bx"
                      alt="No Share Icon"
                    />
                  )}
                </span>
              </div>
            </div>

            <div className="grid-item item5 smallgridgap">
              {tourType === "customized" ? (
                <div className="forsmalldevicebttn ">
                  <Button
                    onClick={() => getPDF(id)}
                    size="lg"
                    className="butnget"
                  >
                    Get Itinerary
                  </Button>{" "}
                  <Button
                    variant="secondary"
                    size="lg"
                    className="butnbook"
                    onClick={handleShow}
                  >
                    GET QUOTE
                  </Button>
                </div>
              ) : (
                <div className="forsmalldevicebttn ">
                  <Button
                    onClick={() => {
                      if (tourPdf && tourPdf.length > 0 && tourPdf[0].src) {
                        window.open(
                          `${process.env.REACT_APP_API_URL}/${tourPdf[0].src}`,
                          "_blank",
                          "noopener,noreferrer"
                        );
                      } else {
                        console.log(
                          "Tour PDF not found, fetching with ID:",
                          id
                        );
                        getPDF(id);
                      }
                    }}
                    size="lg"
                    className="butnget"
                  >
                    Get Itinerary
                  </Button>

                  {isAuthenticated ? (
                    <Button
                      variant="secondary"
                      size="lg"
                      className="butnbook"
                      data-bs-toggle="modal"
                      data-bs-target="#modalBookSec"
                    >
                      Book Now
                    </Button>
                  ) : (
                    <Button
                      type="button"
                      variant="secondary"
                      size="lg"
                      className="butnbook"
                      onClick={handleLoginpage}
                    >
                      Book Now
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <section className="mt-5 for-mobile-view-section">
        <div className="container">
          <div className="row position-relative">
            <div
              className="col-12 col-sm-12 col-lg-8 col-md-12 "
              style={{ marginLeft: "0px" }}
            >
              <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
                <li className="nav-item " role="presentation">
                  <button
                    className="nav-link active for_btntab"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    OVERVIEW
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link for_btntab"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    TOUR OUTLINE
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link for_btntab"
                    id="contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#contact"
                    type="button"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                  >
                    OTHER INFO
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  {data && (
                    <div className="for_upperdiv mt-4">
                      <div className="highLights mb-5 Highlights-mobile-view">
                        <h3 className="for_head">HIGHLIGHTS</h3>
                        <div className="row mt-3 smallrowsize">
                          <div className="col-md  smalldevicesize">
                            <div className="row d-flex for_smallflex">
                              <div className="col-md-3  col-3 d-flex gap-4">
                                <img
                                  className="followUp_icon mt-2"
                                  src={duration}
                                  alt=""
                                ></img>
                                <img src={line} alt="" className=""></img>
                              </div>
                              <div className="col-md-3 col-4 duration-text mt-2">
                                DURATION
                              </div>
                              <div className="col-md-6 col-5 night-text text-nowrap mt-2">
                                {/* {data?.similar_trip?.[0]?.nightorday} Days /{" "}
                                {data?.similar_trip?.[0]?.nightorday + 1} Night */}
                                {durations?.getData?.nightorday}Night/
                                {durations?.getData?.nightorday + 1}Days
                              </div>
                            </div>
                            <div className="row mt-4">
                              <div className="col-md-3 col-3 d-flex gap-4">
                                <img
                                  className="followUp_icon mt-2"
                                  src={rupesicon}
                                  alt=""
                                ></img>
                                <img src={line} alt="" className=""></img>
                              </div>
                              {tourType === "normal" ? (
                                <>
                                  <div className="col-md-4 col-4 duration-text mt-2">
                                    STARTING <br></br>PRICE
                                  </div>
                                  <div className="col-md-4 col-5 night-text pl-1 mt-2">
                                    {PackegPrice?.lowestPackagePrice}
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="col-md-4 col-4 duration-text mt-2">
                                    STARTING <br></br>PRICE
                                  </div>
                                  <span className="col-md-4 col-5 night-text pl-1 mt-2 w-25">
                                    -
                                  </span>
                                </>
                              )}
                            </div>

                            <div className="row mt-4">
                              <div className="col-md-3 col-3 d-flex gap-4">
                                <img
                                  className="followUp_icon mt-2"
                                  src={picuplocation}
                                  alt=""
                                ></img>
                                <img src={line} alt=""></img>
                              </div>

                              <div className="col-md-4 col-4 duration-text mt-2">
                                PICK-UP <br></br>LOCATION
                              </div>

                              <div className="col-md-4 col-5 night-text pl-1 mt-2">
                                {" "}
                                {data.pickAndDrop}
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="col-md-3 col-3  d-flex gap-4  ">
                                <img
                                  className="followUp_icon mt-2"
                                  src={picdroplocation}
                                  alt=""
                                ></img>
                                <img src={line} alt=""></img>
                              </div>

                              <div className="col-md-4 col-4 duration-text mt-2">
                                DROP-OFF <br></br>LOCATION
                              </div>

                              <div className="col-md-4 col-5 night-text pl-1 mt-2">
                                {data.pickAndDrop}
                              </div>
                            </div>
                          </div>
                          <div className="col-md smalldevicefor ">
                            <div className="row d-flex for_smallflex">
                              <div className="col-md-3 col-3 d-flex gap-4">
                                <img
                                  className="followUp_icon mt-2"
                                  src={besttime}
                                  alt=""
                                ></img>
                                <img src={line} alt=""></img>
                              </div>

                              <div className="col-md-4 col-4 duration-text ">
                                BEST TIME
                              </div>
                              <div className="col-md-4 col-5 night-text p-0">
                                {data?.best_time}
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="col-md-3 col-3  d-flex gap-4 ">
                                <img
                                  className="followUp_icon"
                                  src={nuberplace}
                                  alt=""
                                ></img>
                                <img src={line} alt=""></img>
                              </div>

                              <div className="col-md-4 col-4 duration-text text-nowrap">
                                NUMBER OF <br></br> PLACES
                              </div>

                              <div className="col-md-4 col-5 night-text pl-1">
                                {" "}
                                {data.no_of_place}
                              </div>
                            </div>

                            <div className="row mt-4 for_smallflex">
                              <div className="col-md-3 col-3  d-flex gap-4 ">
                                <img
                                  className="followUp_icon"
                                  src={picuplocation}
                                  alt=""
                                ></img>
                                <img src={line} alt=""></img>
                              </div>

                              <div className="col-md-4 col-4 duration-text">
                                ALTITUDE &nbsp;/ REGION
                              </div>

                              <div className="col-md-4 col-5 night-text pl-1">
                                {data.highAltitude} / {data.region}
                              </div>
                            </div>

                            <div className="row mt-4 for_smallflex">
                              <div className="col-md-3 col-3  d-flex gap-4 ">
                                <img
                                  className="followUp_icon"
                                  src={picdroplocation}
                                  alt=""
                                ></img>
                                <img src={line} alt=""></img>
                              </div>

                              <div className="col-md-4 col-4 duration-text">
                                DIFFICULTY &nbsp;
                              </div>

                              <div className="col-md-4 col-5 night-text pl-1 mb-3">
                                {data.difficulty}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* {data?.similar_blog?.length > 0 && ( */}
                      <div className="parentdiv">
                        <h3 className="for_head"> {data?.title}</h3>
                        <h5 className="mainhead">
                          <span className="for_firsthead">
                            {data?.overview && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: data?.overview,
                                }}
                              />
                            )}
                          </span>{" "}
                        </h5>
                      </div>
                      {/* )} */}

                      {data &&
                        data.multiple_overviewdesc &&
                        data.multiple_overviewdesc[0] &&
                        data.multiple_overviewdesc[0].overview_desc ? (
                        <div className="highlight_div mt-4">
                          <h4 className="heading_highlight">
                            {data?.overview_title}
                            {/* hightlight */}
                          </h4>
                          <div className="for_highlight_content d-flex">
                            <h5
                              className="for_firsthead"
                              dangerouslySetInnerHTML={{
                                __html: `${data.multiple_overviewdesc[0].overview_desc}`,
                              }}
                            />
                          </div>
                        </div>
                      ) : null}
                      {data?.overviewNote && (
                        <div className="for_note_div mt-5">
                          <h4 className="for_note_content notesection">
                            NOTE:
                            <div
                              dangerouslySetInnerHTML={{
                                __html: data.overviewNote,
                              }}
                            />
                          </h4>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div
                  className="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div className="for_upperdiv mt-4">
                    {data &&
                      data.Itinerary.length > 0 &&
                      data.Itinerary.map((itinerary: any, index: number) => (
                        <div
                          className="accordion "
                          id={`accordionPanelsStayOpenExample-${index}`}
                          key={index}
                        >
                          <h2 className="accordion-header for_shri_div d-flex mt-4">
                            <div className=" day1 px-1 col-2 d-flex justify-content-center align-items-center">
                              Day {index + 1}
                            </div>
                            <button
                              className="accordion-button collapsed shri_buttn"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#panelsStayOpen-collapse-${index}`}
                              aria-expanded="false"
                              aria-controls={`panelsStayOpen-collapse-${index}`}
                            >
                              {itinerary.Title}
                            </button>
                          </h2>
                          <div
                            id={`panelsStayOpen-collapse-${index}`}
                            className="accordion-collapse collapse  for_shri2_div mt-4"
                          >
                            <div className="accordion-body">
                              <h5 className="pt-2 d-flex for_contentunder">
                                {/* <span className="">
                                  <TbCheckbox />
                                </span> */}
                                <div
                                  style={{ marginLeft: "3%" }}
                                  dangerouslySetInnerHTML={{
                                    __html: itinerary?.Description,
                                  }}
                                  className="for-view-accordion"
                                />
                              </h5>

                              {itinerary.Properties &&
                                itinerary.Properties.length > 0 && (
                                  <div className="d-flex text-dark mobile-properties">
                                    <h5 style={{ marginLeft: "5%" }}>
                                      Inclusions
                                    </h5>
                                    <span className="mx-2">|</span>
                                    <div className="properties-list">
                                      {itinerary.Properties.map(
                                        (
                                          property: any,
                                          propertyIndex: number
                                        ) => (
                                          <div
                                            key={propertyIndex}
                                            className="property-item"
                                            style={{
                                              color: "#dc1b5e",
                                              fontWeight: "500",
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              marginRight:
                                                propertyIndex <
                                                  itinerary.Properties.length - 1
                                                  ? "8px"
                                                  : "0px",
                                            }}
                                          >
                                            {getPropertyIcon(property)}{" "}
                                            {capitalizeFirstLetter(property)}
                                            {propertyIndex <
                                              itinerary.Properties.length - 1 &&
                                              ","}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                )}

                              <div className="pt-3 d-flex">
                                <Swiper
                                  {...swiperParams7}
                                  modules={[Autoplay, Pagination, Navigation]}
                                >
                                  {itinerary.Images?.map(
                                    (image: any, imageIndex: number) => (
                                      <SwiperSlide
                                        className="This_is"
                                        style={{ marginLeft: "3%" }}
                                        key={imageIndex}
                                      >
                                        <img
                                          className="img-fluid Itinerary_images"
                                          src={`${process.env.REACT_APP_API_URL}/uploads/${image}`}
                                          alt={`Image ${imageIndex + 1}`}
                                        />
                                      </SwiperSlide>
                                    )
                                  )}
                                </Swiper>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                    <div className="flex-grow-1" style={{ marginTop: "35px" }}>
                      <hr className="border-dark w-100" />
                    </div>
                    {data?.tourOutlineNote && (
                      <div className="for_note_div mt-5">
                        <h4 className="for_note_content notesection">
                          NOTE:
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data?.tourOutlineNote,
                            }}
                          />
                        </h4>
                      </div>
                    )}
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="contact"
                  role="tabpanel"
                  aria-labelledby="contact-tab"
                >
                  <div className="for_upperdiv mt-4">
                    {data?.summary && data.summary.length > 0 && (
                      <div className="parentdiv3">
                        <h3 className="for_head" style={{ letterSpacing: "0" }}>
                          INCLUSIONS
                        </h3>
                        {data.summary.map((item: any, index: number) => (
                          <div
                            key={index}
                            className="pt-2 d-flex for_contentunder"
                          >
                            {/* <span>
                              <TbCheckbox />
                            </span> */}
                            <div
                              className="for-inclusion-text"
                              style={{ marginLeft: "3%" }}
                              dangerouslySetInnerHTML={{ __html: item }}
                            />
                          </div>
                        ))}
                      </div>
                    )}

                    {data?.summaryExc && data.summaryExc.length > 0 && (
                      <div className="Exclusions_div mt-4">
                        <h4 className="heading_highlight">EXCLUSIONS</h4>
                        <div className="for_highlight_content">
                          {data?.summaryExc?.map((item: any, index: number) => (
                            <h5
                              className="pt-2 d-flex for_exclusioncontent"
                              key={index}
                            >
                              <div
                                className="for-inclusion-text"
                                style={{ marginLeft: "3%" }}
                                dangerouslySetInnerHTML={{
                                  __html: item,
                                }}
                              />
                            </h5>
                          ))}
                        </div>
                      </div>
                    )}
                    {data?.mustCarry &&
                      data.mustCarry.filter(
                        (item: string) => item.trim() !== ""
                      ).length > 0 && (
                        <div className="Exclusions_div mt-4">
                          <h4 className="heading_highlight">
                            MUST <span className="text-dark">CARRY</span>
                          </h4>
                          <div className="for_highlight_content">
                            {data.mustCarry
                              .filter((item: string) => item.trim() !== "")
                              .map((item: any, index: number) => (
                                <h5
                                  key={index}
                                  className="pt-2 d-flex for_exclusioncontent"
                                >
                                  <div
                                    className="for-inclusion-text"
                                    style={{ marginLeft: "3%" }}
                                    dangerouslySetInnerHTML={{ __html: item }}
                                  />
                                </h5>
                              ))}
                          </div>
                        </div>
                      )}

                    {data?.cancellationPolicy &&
                      data.cancellationPolicy.length > 0 && (
                        <div className="Exclusions_div mt-4">
                          <h4 className="heading_highlight">
                            CANCELLATION{" "}
                            <span className="text-dark">POLICY</span>
                          </h4>

                          <div className="for_highlight_content">
                            {data.cancellationPolicy.map(
                              (item: any, index: number) => (
                                <h5
                                  className="pt-2 d-flex for_exclusioncontent"
                                  key={index}
                                >
                                  {/* <span>
                                    <TbCheckbox />
                                  </span> */}
                                  <div
                                    className="for-inclusion-text"
                                    style={{ marginLeft: "3%" }}
                                    dangerouslySetInnerHTML={{ __html: item }}
                                  />
                                </h5>
                              )
                            )}
                          </div>
                        </div>
                      )}

                    {data?.otherinfo_note && (
                      <div className="for_note_div mt-5">
                        <h4 className="for_note_content notesection">
                          NOTE:
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data?.otherinfo_note,
                            }}
                          />
                        </h4>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-lg-4 col-md-12 ">
              {tourType === "customized" ? (
                <div className="tripdetails-enquriy">
                  <div className="row d-flex px-3 mt-3 for-enquriy">
                    <h4 className="text-center Enquriy-text">Enquiry</h4>
                    <div className="col-lg-6 ">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1"
                          name="name"
                          placeholder="Enter Your Name"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                          value={formData1.name}
                          onChange={handleChange1}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 ">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Destination<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text "
                          className="form-control"
                          id="exampleFormControlInput1"
                          name="destination"
                          placeholder="Enter Your Destination"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                          value={tour_name}
                          onChange={handleChange1}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex px-3">
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Number of Travellers
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="tel"
                          className="form-control"
                          id="exampleFormControlInput1"
                          name="numberOfTraveller"
                          placeholder="Enter Number Of Travellers"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                          value={formData1.numberOfTraveller}
                          onChange={handleChange1}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Email ID<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="email"
                          className={`form-control ${emailError ? "is-invalid" : ""
                            }`}
                          id="exampleFormControlInput1"
                          name="email"
                          placeholder="Enter Your Email ID"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                          value={formData1.email}
                          onChange={handleChange1}
                        />
                        {emailError && (
                          <div className="invalid-feedback">{emailError}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex px-3">
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Month
                        </label>
                        <select
                          className="form-select"
                          id="exampleFormControlInput1"
                          name="traveler_month"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                          value={formData1.traveler_month}
                          onChange={handleChange1}
                        >
                          <option value="">Select Month</option>
                          <option value="January">January</option>
                          <option value="February">February</option>
                          <option value="March">March</option>
                          <option value="April">April</option>
                          <option value="May">May</option>
                          <option value="June">June</option>
                          <option value="July">July</option>
                          <option value="August">August</option>
                          <option value="September">September</option>
                          <option value="October">October</option>
                          <option value="November">November</option>
                          <option value="December">December</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Mobile Number<span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="input-group">
                          <input
                            type="tel"
                            className={`form-control ${mobileError ? "is-invalid" : ""
                              }`}
                            id="exampleFormControlInput1"
                            name="mobile"
                            placeholder="Enter Mobile Number"
                            style={{
                              border: "0.76px solid rgba(255, 169, 35, 1)",
                            }}
                            value={formData1.mobile}
                            onChange={handleChange1}
                            onInput={(e: any) => {
                              e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              ); // Allow only numeric input
                            }}
                            maxLength={10}
                          />
                          <button
                            type="button"
                            onClick={() => sendOtp(formData1.mobile)}
                            className="btn btn-primary"
                            disabled={formData1.mobile.length !== 10} // Enable only if valid
                            style={{
                              padding: "5px 10px",
                              fontSize: "10px",
                              backgroundColor:
                                formData1.mobile.length === 10
                                  ? "#ffa923"
                                  : "gray",
                              borderColor:
                                formData1.mobile.length === 10
                                  ? "#ffa923"
                                  : "gray",
                              cursor:
                                formData1.mobile.length === 10
                                  ? "pointer"
                                  : "not-allowed",
                            }}
                          >
                            Send OTP
                          </button>
                        </div>
                        {mobileError && (
                          <div
                            className="invalid-feedback"
                            style={{ fontSize: "12px", marginTop: "5px" }}
                          >
                            {mobileError}
                          </div>
                        )}
                      </div>
                    </div>
                    {otpVisible && (
                      <div className="mt-3  w-50 m-auto">
                        <input
                          type="text"
                          className="form-control"
                          name="otp"
                          maxLength={4}
                          placeholder="Enter OTP"
                          value={formData1.otp}
                          onChange={handleChange1}
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                        />
                      </div>
                    )}
                  </div>

                  <div className="modal-footer border-0 mt-3">
                    <button
                      className="btn btn-color m-auto mb-2"
                      style={{
                        padding: "7px 37px",
                        fontSize: "20px",
                        borderRadius: "10px",
                        background: "#ffA923",
                        margin: "4px 2px",
                        color: "#FFF",
                      }}
                      onClick={() => handleVerifyOtp(formData1.otp, 1)}
                    disabled={!isFormValid2()}
                    >
                      Send
                    </button>
                  </div>
                </div>
              ) : (
                <div className="parentdiv2 ">
                  <h4 className="for_datescosting pt-3 text-center">
                    <span style={{ color: "#DC1B5E" }}>Dates</span> & Costing
                  </h4>
                  <div className="mt-4 mb-2 text-center">
                    <div>
                      {batch &&
                        Array.isArray(Object.keys(batch)) &&
                        Object.keys(batch).length > 0 &&
                        Object.keys(batch).map((month: any) => (
                          <button
                            key={month}
                            className={`for_buttonMonth mx-2 ${selectedMonth === month ? "active" : ""
                              } mt-2`}
                            style={{ fontSize: "17px", fontWeight: "500" }}
                            onClick={() => handleMonthClick(month)}
                          >
                            {month.substring(0, 3)}{" "}
                          </button>
                        ))}
                    </div>

                    <div className="for_drop ">
                      <select
                        className="form-select for_dro2"
                        aria-label="Default select example"
                        value={selectedOption}
                        onChange={(e: any) => setSelectedOption(e.target.value)}
                      >
                        {batch &&
                          batch[selectedMonth]?.length &&
                          batch[selectedMonth].map(
                            (date: any, index: number) => {
                              const startDate = new Date(date.start_date);
                              const endDate = new Date(date.end_date);

                              const formattedStartDate = `${startDate.getDate()} ${startDate.toLocaleString(
                                "en-GB",
                                { month: "short" }
                              )} ${startDate.getFullYear()}`;
                              const formattedEndDate = `${endDate.getDate()} ${endDate.toLocaleString(
                                "en-GB",
                                { month: "short" }
                              )} ${endDate.getFullYear()}`;

                              const label = data?.DateArr?.[0]?.label;

                              return (
                                <option
                                  key={index}
                                  value={JSON.stringify(date)}
                                  selected
                                  className="for_option"
                                >
                                  {`${formattedStartDate} - ${formattedEndDate}${label ? ` (${label})` : ""
                                    }`}
                                </option>
                              );
                            }
                          )}
                      </select>
                    </div>

                    <div className="d-flex mt-4">
                      <div className="for_box col-lg-4 col-md-4 col-sm-4 col-4">
                        <p className="for_box_content pb-3">Package Type</p>
                      </div>

                      <div className="for_box col-lg-4 col-md-4 col-4">
                        <p className="for_box_content pb-3">
                          Discount Price/ Person
                        </p>
                      </div>
                      <div className="for_box col-lg-4 col-md-4 col-4">
                        <p className="for_box_content pb-3">
                          Selling Price/ Person
                        </p>
                      </div>
                    </div>

                    {selectedOption &&
                      JSON.parse(
                        JSON.parse(selectedOption)?.packageType_price[0] || "[]"
                      )?.map((packageObj: any, index: number) => (
                        <div className="d-flex">
                          <div className="for_box col-lg-4 col-md-4 col-4">
                            <p className="for_box_content pb-3">
                              {packageObj.packType}
                            </p>
                          </div>

                          <div className="for_box2 col-lg-4 col-md-4 col-4 ">
                            <p className="for_box_content pb-3">
                              {packageObj?.packPrice}
                            </p>
                          </div>
                          <div className="for_box2 col-lg-4 col-md-4 col-4">
                            <s>
                              <p className="for_box_content pb-3">
                                {
                                  JSON.parse(
                                    JSON.parse(selectedOption)
                                      ?.packageType_striking_price[0] || "[]"
                                  )[index].packPricestriker
                                }
                              </p>
                            </s>
                          </div>
                        </div>
                      ))}
                  </div>

                  {selectedOption &&
                    JSON.parse(
                      JSON.parse(selectedOption)?.roomSharing_price[0] || "[]"
                    )?.filter(
                      (packageObj: any) =>
                        packageObj?.roomPrice && packageObj?.roomPrice > 0
                    )?.length > 0 && ( // Filter for valid and greater than 0 roomPrice // Check if there's valid data
                      <div className="mt-3">
                        <div className="d-flex mt-4">
                          <div className="for_box col-lg-4 col-md-6 col-6">
                            <p className="for_box_content pb-3">Room Sharing</p>
                          </div>

                          <div className="for_box col-lg-8 col-md-6 col-6">
                            <p className="for_box_content pb-3">Price/Person</p>
                          </div>
                        </div>

                        {JSON.parse(
                          JSON.parse(selectedOption)?.roomSharing_price[0] ||
                          "[]"
                        )
                          ?.filter(
                            (packageObj: any) =>
                              packageObj?.roomPrice && packageObj?.roomPrice > 0
                          ) // Filter again for valid and > 0 roomPrice
                          ?.map((packageObj: any, index: number) => (
                            <div className="d-flex" key={index}>
                              <div className="for_box col-lg-4 col-md-6 col-6">
                                <p className="for_box_content pb-3">
                                  {packageObj?.roomType}
                                </p>
                              </div>
                              <div className="for_box2 col-lg-8 col-md-6 col-6">
                                <p className="for_box_content pb-3">
                                  {packageObj?.roomPrice}
                                </p>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                  <div className="for_drop">
                    <select
                      className="form-select for_select"
                      aria-label="Default select example"
                      onChange={(e) => setBoardingPointCity(e.target.value)}
                    >
                      <option value="">Select Boarding City</option>
                      {data?.boarding_point?.map((ele: any) => (
                        <option key={ele} value={ele}>
                          {ele}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="mt-3">
                    {boardingPointCity &&
                      selectedOption &&
                      JSON.parse(
                        JSON.parse(selectedOption)?.boarding_point_price[0] ||
                        "[]"
                      )?.map(
                        (boarding: any) =>
                          boarding.bp === boardingPointCity &&
                          boarding.bp_price.map((bp: any, index: number) => {
                            const key =
                              Object.keys(bp)[0] === "singleBoardPrice"
                                ? "Boarding Price"
                                : Object.keys(bp)[0];
                            const value = Object.values(
                              bp
                            )[0] as React.ReactNode;

                            // Only render if value is not empty
                            if (value) {
                              return (
                                <div className="d-flex" key={index}>
                                  <div className="for_box col-lg-6 col-md-6 col-6">
                                    <p className="for_box_content pb-3">
                                      {key}
                                    </p>
                                  </div>
                                  <div className="for_box2 col-lg-6 col-md-6 col-6">
                                    <p className="for_box_content pb-3">
                                      {value}
                                    </p>
                                  </div>
                                </div>
                              );
                            }
                            return null; // Don't render anything if value is empty
                          })
                      )}
                  </div>

                  <div className="">
                    {coupon && coupon.length > 0 && (
                      <div className="accordion mt-3" id="accordionExample">
                        <div className="accordion-item">
                          <h2
                            className="accordion-header for_shri_div"
                            id="headingOne"
                          >
                            <button
                              className="accordion-button buttonstyl collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="false"
                              aria-controls="collapseOne"
                            >
                              Coupon Codes
                            </button>
                          </h2>
                          <div
                            id="collapseOne"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            {coupon.map((item: any, index: number) => (
                              <div key={index} className="accordion-body">
                                <div className="custom_class_coupon">
                                  <p className="csty">{item.coupon_code}</p>
                                  <div>
                                    <p className="lsty">
                                      {item.coupon_description}
                                    </p>
                                    <p className="expdate">
                                      Exp -{" "}
                                      {new Intl.DateTimeFormat("en-GB", {
                                        day: "2-digit",
                                        month: "short",
                                        year: "numeric",
                                      }).format(
                                        new Date(item.coupon_expiry_date)
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      className="container-fluid d-flex justify-content-center align-items-center"
                      style={{ height: "85px" }}
                    >
                      {isAuthenticated ? (
                        <button
                          type="button"
                          className="bookNow"
                          data-bs-toggle="modal"
                          data-bs-target="#modalBookSec"
                        >
                          Book Now
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="bookNow"
                          onClick={handleLoginpage}
                        >
                          Book Now
                        </button>
                      )}
                    </div>
                    <div className="price-notes">
                      <ul>
                        {data?.domesticorInternational === "domestic" ? (
                          <li>
                            Price mentioned is{" "}
                            {selectedOption && JSON.parse(selectedOption)
                              ? JSON.parse(selectedOption).gst
                              : ""}{" "}
                            of GST
                          </li>
                        ) : (
                          <>
                            <li>
                              Price mentioned is{" "}
                              {selectedOption && JSON.parse(selectedOption)
                                ? JSON.parse(selectedOption).gst
                                : ""}{" "}
                              of GST
                            </li>
                            <li>
                              Price mentioned is{" "}
                              {selectedOption && JSON.parse(selectedOption)
                                ? JSON.parse(selectedOption).tcs
                                : ""}{" "}
                              of TCS
                            </li>
                          </>
                        )}
                        <li>Price mentioned above is on a per person basis</li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <div className="center-menu_chat" style={{ textAlign: "end" }}>
        {showMenuButton && (
          <label className="menu-button" htmlFor="menu-open" aria-hidden="true">
            <img
              src={vector}
              alt=""
              // onClick={scrollToTop}
              className="for_girlicon"
            ></img>
          </label>
        )}
        <input
          className="menu-open"
          id="menu-open"
          type="checkbox"
          aria-hidden="true"
        />
        <nav className="menu" role="navigation">
          <div
            className="send"
            style={{ paddingBottom: "5px", cursor: "pointer" }}
          >
            <img src={sendImage} onClick={handleImageClick} alt="" />
            <img src={sendEnquiry} alt="" />
            <Modal show={isModalOpen} onHide={closeModal}>
              <Modal.Header closeButton>
                <Modal.Title>Enquiry</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="">
                  <div className="row d-flex px-3 mt-3 ">
                    <div className="col-lg-6 ">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput2"
                          className="form-label"
                        >
                          Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput2"
                          name="name"
                          placeholder="Enter Your Name"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                          value={formData2.name}
                          onChange={handleChange2}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 ">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput2"
                          className="form-label"
                        >
                          Destination<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text "
                          className="form-control"
                          id="exampleFormControlInput2"
                          name="destination"
                          placeholder="Enter Your Destination"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                          value={tour_name}
                          onChange={handleChange2}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row d-flex px-3">
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput2"
                          className="form-label"
                        >
                          Number of Travellers
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="tel"
                          className="form-control"
                          id="exampleFormControlInput2"
                          name="numberOfTraveller"
                          placeholder="Enter Number Of Travellers"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                          value={formData2.numberOfTraveller}
                          onChange={handleChange2}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput2"
                          className="form-label"
                        >
                          Email ID<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="email"
                          className={`form-control ${emailError ? "is-invalid" : ""
                            }`}
                          id="exampleFormControlInput2"
                          name="email"
                          placeholder="Enter Your Email ID"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                          value={formData2.email}
                          onChange={handleChange2}
                        />
                        {emailError && (
                          <div className="invalid-feedback">{emailError}</div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row d-flex px-3">
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput2"
                          className="form-label"
                        >
                          Month
                        </label>
                        <select
                          className="form-select"
                          id="exampleFormControlInput2"
                          name="traveler_month"
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                          value={formData2.traveler_month}
                          onChange={handleChange2}
                        >
                          <option value="">Select Month</option>
                          <option value="January">January</option>
                          <option value="February">February</option>
                          <option value="March">March</option>
                          <option value="April">April</option>
                          <option value="May">May</option>
                          <option value="June">June</option>
                          <option value="July">July</option>
                          <option value="August">August</option>
                          <option value="September">September</option>
                          <option value="October">October</option>
                          <option value="November">November</option>
                          <option value="December">December</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput2"
                          className="form-label"
                        >
                          Mobile Number<span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="input-group">
                          <input
                            type="tel"
                            className={`form-control ${mobileError ? "is-invalid" : ""
                              }`}
                            id="exampleFormControlInput2"
                            name="mobile"
                            placeholder="Enter Mobile Number"
                            style={{
                              border: "0.76px solid rgba(255, 169, 35, 1)",
                            }}
                            value={formData2.mobile}
                            onChange={handleChange2}
                            onInput={(e: any) => {
                              e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                            }}
                            maxLength={10}
                          />
                          <button
                            type="button"
                            onClick={() => sendOtp(formData2.mobile)}
                            className="btn btn-primary"
                            disabled={formData2.mobile.length !== 10}
                            style={{
                              padding: "5px 10px",
                              fontSize: "10px",
                              backgroundColor:
                                formData2.mobile.length === 10
                                  ? "#ffa923"
                                  : "gray",
                              borderColor:
                                formData2.mobile.length === 10
                                  ? "#ffa923"
                                  : "gray",
                              cursor:
                                formData2.mobile.length === 10
                                  ? "pointer"
                                  : "not-allowed",
                            }}
                          >
                            Send OTP
                          </button>
                        </div>
                        {mobileError && (
                          <div className="invalid-feedback">{mobileError}</div>
                        )}
                      </div>
                    </div>
                    {otpVisible && (
                      <div className="mt-3  w-50 m-auto">
                        <input
                          type="text"
                          className="form-control"
                          name="otp"
                          maxLength={4}
                          placeholder="Enter OTP"
                          value={formData2.otp}
                          onChange={handleChange2}
                          style={{
                            border: "0.76px solid rgba(255, 169, 35, 1)",
                          }}
                        />
                      </div>
                    )}
                  </div>

                  <div className="modal-footer border-0">
                    <button
                      className="btn btn-color m-auto mb-2"
                      style={{
                        padding: "7px 37px",
                        fontSize: "20px",
                        borderRadius: "10px",
                        background: "#ffA923",
                        margin: "4px 2px",
                        color: "#FFF",
                      }}
                      onClick={() => handleVerifyOtp(formData2.otp, 2)}
                      disabled={!isFormValid()}  // Disable button if form is not valid
                    >
                      Send
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>

          <div className="call" style={{ paddingBottom: "5px" }}>
            {data?.callexpertnumber?.[0] ? ( // Check if callexpertnumber[0] is not null, undefined, or empty
              isMobile ? (
                <>
                  <img
                    src={callImage}
                    alt="Call Icon"
                    onClick={handleOpenPopup} // Open popup on image click
                  />
                  <img
                    src={callExpert}
                    alt="Expert Icon"
                    onClick={handleOpenPopup} // Open popup on image click
                  />
                </>
              ) : (
                <a href={`tel:${data.callexpertnumber[0]}`}>
                  <img
                    src={callImage}
                    alt="Call Icon"
                    onClick={handleOpenPopup}
                  />
                  <img
                    src={callExpert}
                    alt="Expert Icon"
                    onClick={handleOpenPopup}
                  />
                </a>
              )
            ) : null}{" "}
            {/* If callexpertnumber[0] is null or empty, this section will not render */}
            {showPopup &&
              data?.callexpertnumber?.[0] &&
              ReactDOM.createPortal(
                <div className="popup-container">
                  <div className="popup-content">
                    <span className="close-btn" onClick={handleClosePopup}>
                      ×
                    </span>
                    <h3>Call Expert</h3>
                    <p>Phone Number :</p>
                    {data.callexpertnumber[0]
                      .split(", ")
                      .map((phone: any, index: any) => (
                        <p key={index} className="for_callexpert">
                          <a href={`tel:${phone}`} className="for_callexpert">
                            <i className="fas fa-phone"></i> {phone}
                          </a>
                        </p>
                      ))}
                  </div>
                </div>,
                document.body
              )}
          </div>

          {data?.whatsapp_number && (
            <div className="wtsapp" style={{ paddingBottom: "5px" }}>
              <a
                href={`https://api.whatsapp.com/send?phone=${data?.whatsapp_number}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={wtspImage} alt="" />
                <img src={wtsp_icon} alt="" />
              </a>
            </div>
          )}
        </nav>
      </div>

      {data?.multiple_image && data?.multiple_image.length > 0 && (
        <section className="similar-trip mb-4 mt-4">
          <div className="container">
            <div
              className="d-flex align-items-center similar-trips-view"
              style={{ padding: "2%" }}
            >
              <div>
                <h4 className="mr-3">
                  {" "}
                  <span className="similarclasses">PHOTOS</span>
                </h4>
              </div>
              <div
                className="flex-grow-1"
                style={{ paddingLeft: "1%", paddingRight: "1%" }}
              >
                <hr className="border-dark w-100" />
              </div>
              <div
                className="slider-icon-gol-div"
                style={{ marginRight: "-2%" }}
              >
                <span
                  className="less-icon-gola"
                  onClick={() => {
                    if (swiper3 !== null && swiper3.slidePrev) {
                      swiper3.slidePrev();
                    }
                  }}
                >
                  <LiaLessThanSolid />
                </span>
                <span className="less-icon-gola" onClick={handleNextClick3}>
                  <LiaGreaterThanSolid />
                </span>
              </div>
            </div>
            <Swiper {...swiperParams5} onSwiper={setSwiper3}>
              {data.multiple_image.map((image: any, index: any) => (
                <SwiperSlide key={index}>
                  <img
                    src={`${process.env.REACT_APP_API_URL}/${image.src}`}
                    alt={`Image ${index + 1}`}
                    className="img-fluid swiper_2nd_photo"
                    onClick={() => ImageClick(index)}
                    style={{ cursor: "pointer" }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </section>
      )}
      {showModal && (
        <Lightbox
          open={showModal}
          close={() => setShowModal(false)}
          slides={slides}
          index={activeIndex}
        />
      )}
      {data?.multiple_url &&
        data?.multiple_url.length > 0 &&
        data?.multiple_url.every((url: any) => url.youtube_url) && (
          <section className="similar-trip mb-4">
            <div className="container">
              <div
                className="d-flex align-items-center similar-trips-view"
                style={{ padding: "2%" }}
              >
                <div>
                  <h4 className="mr-3">
                    {" "}
                    <span className="similarclasses">VIDEOS</span>
                  </h4>
                </div>
                <div
                  className="flex-grow-1"
                  style={{ paddingLeft: "1%", paddingRight: "1%" }}
                >
                  <hr className="border-dark w-100" />
                </div>
                <div
                  className="slider-icon-gol-div"
                  style={{ marginRight: "-2%" }}
                >
                  <span
                    className="less-icon-gola"
                    onClick={() => {
                      if (swiper2 !== null && swiper2.slidePrev) {
                        swiper2.slidePrev();
                      }
                    }}
                  >
                    <LiaLessThanSolid />
                  </span>
                  <span className="less-icon-gola" onClick={handleNextClick2}>
                    <LiaGreaterThanSolid />
                  </span>
                </div>
              </div>

              <Swiper {...swiperParams5} onSwiper={setSwiper2}>
                {data?.multiple_url?.map((multiple_url: any, index: number) => (
                  <SwiperSlide key={index}>
                    <iframe
                      className="img-fluid yt_vid swiper_2nd_photo"
                      src={`https://www.youtube.com/embed/${extractYouTubeID(
                        multiple_url?.youtube_url
                      )}`}
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </section>
        )}

      {data?.similar_trip && data?.similar_trip?.length > 0 && (
        <section className="similar-trip mb-4">
          <div className="container">
            <div
              className="d-flex align-items-center similar-trips-view"
              style={{ padding: "2%" }}
            >
              <div>
                <h4 className="mr-3">
                  {" "}
                  <span className="similarclasses">SIMILAR </span>
                  <span className="simTrip">TRIPS</span>{" "}
                </h4>
              </div>
              <div
                className="flex-grow-1"
                style={{ paddingLeft: "1%", paddingRight: "1%" }}
              >
                <hr className="border-dark w-100" />
              </div>
              <div
                className="slider-icon-gol-div"
                style={{ marginRight: "-2%" }}
              >
                <span
                  className="less-icon-gola"
                  onClick={() => {
                    if (swiper1 !== null && swiper1.slidePrev) {
                      swiper1.slidePrev();
                    }
                  }}
                >
                  <LiaLessThanSolid />
                </span>
                <span className="less-icon-gola" onClick={handleNextClick1}>
                  <LiaGreaterThanSolid />
                </span>
              </div>
            </div>
            <Swiper {...swiperParams5} onSwiper={setSwiper1}>
              {data?.similar_trip?.map((similar_trip: any, index: number) => (
                <SwiperSlide key={index}>
                  <div
                    className="mainimg "
                    onClick={() => {
                      similarTrip(similar_trip);
                    }}
                  >
                    <img
                      src={`${process.env.REACT_APP_API_URL}/${similar_trip?.tour_image?.[0]?.src}`}
                      className="img-fluid forsimilar"
                    />
                    <div className="childmainimg">
                      <p>
                        {similar_trip?.title.split(" ").slice(0, 6).join(" ")}
                        {similar_trip?.title.split(" ").length > 6 ? "..." : ""}
                      </p>
                      <p className="data-night-view">
                        <span className="for_date_icon">
                          <FaCalendarAlt />
                        </span>{" "}
                        {similar_trip?.nightorday + 1}D/
                        {similar_trip?.nightorday}N
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </section>
      )}
      {data?.similar_blog && data?.similar_blog?.length > 0 && (
        <section className="similar-trip mb-4">
          <div className="container">
            <div
              className="d-flex align-items-center similar-trips-view"
              style={{ padding: "2%" }}
            >
              <div>
                <h4 className="mr-3">
                  <span className="similarclasses">SIMILAR </span>
                  <span className="simTrip">BLOGS</span>{" "}
                </h4>
              </div>
              <div
                className="flex-grow-1"
                style={{ paddingLeft: "1%", paddingRight: "1%" }}
              >
                <hr className="border-dark w-100" />
              </div>
              <div
                className="slider-icon-gol-div"
                style={{ marginRight: "-2%" }}
              >
                <span className="less-icon-gola" onClick={handlePrevClick}>
                  <LiaLessThanSolid />
                </span>
                <span className="less-icon-gola" onClick={handleNextClick}>
                  <LiaGreaterThanSolid />
                </span>
              </div>
            </div>
            <Swiper {...swiperParams5} onSwiper={setSwiper}>
              {data?.similar_blog?.map((blog: any, index: number) => (
                <SwiperSlide key={index}>
                  {/* <Link to={`/blogdetails/${blog._id}`} key={index}> */}
                  <div
                    className="mainimg"
                    key={index}
                    onClick={() => {
                      const id = blog._id;
                      const slugUrl = blog?.title
                        ?.toLowerCase()
                        .replace(/\s+/g, "-") // Replace spaces with hyphens
                        .replace(/\//g, "-") // Replace forward slashes with hyphens
                        .replace(/\((.*?)\)/g, "$1"); // Remove parentheses and their contents

                      navigate(`/blogdetails/${slugUrl}`, {
                        state: id,
                      });
                    }}
                  >
                    <img
                      src={`${process.env.REACT_APP_API_URL}/${blog.blog_image?.[0]?.src}`}
                      className="img-fluid forsimilar"
                      alt="Blog Image"
                    />
                    v{" "}
                    <div className="childmainimgblog">
                      <p>
                        {blog.title.split(" ").slice(0, 6).join(" ")}
                        {blog.title.split(" ").length > 6 ? "..." : ""}
                      </p>
                    </div>
                  </div>
                  {/* </Link> */}
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </section>
      )}
      <div
        className="modal fade"
        id="modalBookSec"
        aria-labelledby="modalBookSecLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalBookSecLabel">
                Tour Details
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeBtnRef}
              ></button>
            </div>
            <div className="modal-body">
              <div className="container">
                <div className="row ">
                  <div className="col col-md-6 col-12 tourtt">
                    <p className="h5">Tour Date</p>

                    <select
                      className="form-select for_select_book for_font"
                      aria-label="Default select example"
                      value={selectedOption}
                      onChange={(e) => setSelectedOption(e.target.value)}
                    >
                      <option value="" disabled key="select tour date">
                        Select Batch
                      </option>
                      {data &&
                        Array.isArray(data?.select_batch) &&
                        data?.select_batch.length &&
                        data?.select_batch.map((ele: any, index: number) => {
                          const formatDate = (dateString: string) => {
                            const date = new Date(dateString);
                            const monthNames = [
                              "Jan",
                              "Feb",
                              "Mar",
                              "Apr",
                              "May",
                              "Jun",
                              "Jul",
                              "Aug",
                              "Sep",
                              "Oct",
                              "Nov",
                              "Dec",
                            ];
                            const day = String(date.getDate()).padStart(2, "0");
                            const monthIndex = date.getMonth();
                            const year = date.getFullYear();
                            return `${day} ${monthNames[monthIndex]} ${year}`;
                          };

                          const startDate = formatDate(ele.start_date);
                          const endDate = formatDate(ele.end_date);
                          const label = data?.DateArr?.[0]?.label;

                          return (
                            <option key={ele} value={JSON.stringify(ele)}>
                              {`${startDate} - ${endDate}${label ? ` (${label})` : ""
                                }`}
                            </option>
                          );
                        })}
                    </select>

                    <div className="card mt-3">
                      <div className="card-body p-0">
                        <h5 className="card-title text-center mt-2 mb-2">
                          Package Type
                        </h5>
                        <div className="package_detail px-3 d-flex justify-content-between align-items-center flex-column">
                          <div className="w-100 d-flex ">
                            <div className="detaiL-row d-flex justify-content-start align-items-center">
                              <p className="h6">Type</p>
                            </div>
                            <div className="detaiL-row d-flex justify-content-center align-items-center">
                              <p className="h6">Price Per Person</p>
                            </div>
                            <div className="detaiL-row d-flex justify-content-center align-items-center">
                              <i className="fa-solid fa-user fa-1x"></i>
                            </div>
                          </div>
                          <div className="clearfix mt-1"></div>
                          {selectedOption &&
                            JSON.parse(
                              JSON.parse(selectedOption)
                                ?.packageType_price[0] || "[]"
                            )?.map((packageObj: any, index: number) => (
                              <div className="w-100 d-flex ">
                                <div className="detaiL-row d-flex justify-content-start align-items-center">
                                  <p className="h6">{packageObj.packType}</p>
                                </div>
                                <div className="detaiL-row d-flex justify-content-center align-items-center">
                                  <p className="h6">{packageObj.packPrice}</p>
                                </div>
                                <div className="detaiL-row d-flex justify-content-center align-items-center">
                                  <div className="for_number_trip mt-2">
                                    <div>
                                      <h5 className="d-inline-block">
                                        <span
                                          className="for_minus cursor mx-1"
                                          onClick={(e: any) =>
                                            decrementPackageTypeCount(index)
                                          }
                                        >
                                          -
                                        </span>{" "}
                                        {calculationObj.travel_packageType_data[
                                          index
                                        ]?.persons || 0}{" "}
                                        <span
                                          className="for_plus cursor mx-1"
                                          onClick={(e: any) =>
                                            incrementPackageTypeCount(index)
                                          }
                                        >
                                          +
                                        </span>
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                        <hr className="hr mx-2 mt-1"></hr>
                        <div className="package_detail px-3 d-flex justify-content-between align-items-center ">
                          <div className="detaiL-row d-flex justify-content-start align-items-center">
                            <p className="h6">Total : Adults</p>
                          </div>
                          <div className="detaiL-row d-flex justify-content-center align-items-center"></div>
                          <div className="detaiL-row d-flex justify-content-center align-items-center ">
                            <p className="h6">
                              {totalAdults() -
                                calculationObj?.child_count -
                                calculationObj?.infant_count}
                            </p>
                          </div>
                        </div>
                        <hr className="hr mx-2 mt-1"></hr>
                        <div className="package_detail px-3 d-flex justify-content-between align-items-center flex-column">
                          {(selectedOption && JSON.parse(selectedOption)?.child_price !== null) ||
                            (selectedOption && JSON.parse(selectedOption)?.infant_price !== null) ? (
                            <>
                              {JSON.parse(selectedOption)?.child_price !== null &&
                                data?.childfrom &&
                                data?.childto &&
                                data?.childfrom.trim() !== "" &&
                                data?.childto.trim() !== "" && (
                                  <div className="w-100 d-flex ">
                                    <div className="detaiL-row d-flex justify-content-start align-items-center">
                                      <p className="h6">
                                        Kids ( Age {data?.childfrom}-{data?.childto} )
                                      </p>
                                    </div>
                                    <div className="detaiL-row d-flex justify-content-center align-items-center">
                                      <p className="h6">{JSON.parse(selectedOption)?.child_price}</p>
                                    </div>

                                    <div className="detaiL-row d-flex justify-content-center align-items-center">
                                      <div className="for_number_trip">
                                        <div>
                                          <h5 className="d-inline-block">
                                            <span
                                              className="for_minus cursor mx-1"
                                              onClick={() =>
                                                calculationObj.child_count > 0 &&
                                                setCalculationObj({
                                                  ...calculationObj,
                                                  child_count: calculationObj.child_count - 1,
                                                })
                                              }
                                            >
                                              -
                                            </span>{" "}
                                            {calculationObj?.child_count}{" "}
                                            <span
                                              className="for_plus cursor mx-1"
                                              onClick={() =>
                                                setCalculationObj({
                                                  ...calculationObj,
                                                  child_count: calculationObj.child_count + 1,
                                                })
                                              }
                                            >
                                              +
                                            </span>
                                          </h5>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                              {JSON.parse(selectedOption)?.infant_price !== null &&
                                data?.infantfrom &&
                                data?.infantto &&
                                data?.infantfrom.trim() !== "" &&
                                data?.infantto.trim() !== "" && (
                                  <div className="w-100 d-flex ">
                                    <div className="detaiL-row d-flex justify-content-start align-items-center">
                                      <p className="h6">
                                        Infant ( Age {data?.infantfrom}-{data?.infantto} )
                                      </p>
                                    </div>
                                    <div className="detaiL-row d-flex justify-content-center align-items-center">
                                      <p className="h6">{JSON.parse(selectedOption)?.infant_price}</p>
                                    </div>

                                    <div className="detaiL-row d-flex justify-content-center align-items-center">
                                      <div className="for_number_trip">
                                        <div>
                                          <h5 className="d-inline-block">
                                            <span
                                              className="for_minus cursor mx-1"
                                              onClick={() =>
                                                calculationObj.infant_count > 0 &&
                                                setCalculationObj({
                                                  ...calculationObj,
                                                  infant_count: calculationObj.infant_count - 1,
                                                })
                                              }
                                            >
                                              -
                                            </span>{" "}
                                            {calculationObj?.infant_count}{" "}
                                            <span
                                              className="for_plus cursor mx-1"
                                              onClick={() =>
                                                setCalculationObj({
                                                  ...calculationObj,
                                                  infant_count: calculationObj.infant_count + 1,
                                                })
                                              }
                                            >
                                              +
                                            </span>
                                          </h5>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              <hr className="hr mx-2 mt-1"></hr>

                              {/* Show total only if either Kids or Infant is shown */}
                              <div className="w-100 d-flex ">
                                <div className="detaiL-row d-flex justify-content-start align-items-center">
                                  <p className="h6">Total : Kids/ Infant</p>
                                </div>
                                <div className="detaiL-row d-flex justify-content-center align-items-center"></div>
                                <div className="detaiL-row d-flex justify-content-center align-items-center">
                                  <p className="h6">
                                    {(calculationObj?.child_count || 0) + (calculationObj?.infant_count || 0)}
                                  </p>
                                </div>
                              </div>
                            </>
                          ) : null}


                        </div>



                        {data?.select_batch[0]?.tourDetailsNote && (
                          <div className="package_detail px-3 d-flex justify-content-between align-items-center ">
                            <p className="h6" style={{ color: "red" }}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    data?.select_batch[0]?.tourDetailsNote,
                                }}
                              />
                            </p>
                          </div>
                        )}

                        <hr className="hr mx-2 mt-1"></hr>
                        <div className="package_detail px-3 d-flex justify-content-between align-items-center ">
                          <div className="detaiL-row d-flex justify-content-start align-items-center">
                            <p className="h6 text-nowrap">
                              Total Number Of Traveller
                            </p>
                          </div>
                          <div className="detaiL-row d-flex justify-content-center align-items-center"></div>
                          <div className="detaiL-row d-flex justify-content-center align-items-center">
                            <p className="h6 text-center">{totalAdults()}</p>
                          </div>
                        </div>
                        <hr className="hr mx-2 mt-1"></hr>
                      </div>
                    </div>
                  </div>
                  <div className="col col-md-6 col-12 tourtt">
                    <p className="h5">Boarding Point</p>
                    <select
                      className="form-select for_select_book"
                      aria-label="select example"
                      value={boardingPointCity}
                      onChange={(e) => {
                        const selectedCity = e.target.value;
                        setBoardingPointCity(selectedCity);

                        const firstOption = JSON.parse(
                          JSON.parse(selectedOption)?.boarding_point_price[0] ||
                          "[]"
                        )?.find((boarding: any) => boarding.bp === selectedCity)
                          ?.bp_price?.[0];

                        if (firstOption) {
                          setBoardingPointTypeObj(JSON.stringify(firstOption));
                        }
                      }}
                    >
                      <option value="" disabled>
                        Select Boarding City
                      </option>

                      {data?.boarding_point?.map((ele: any) => (
                        <option key={ele} value={ele}>
                          {ele}
                        </option>
                      ))}
                    </select>

                    <select
                      className="form-select for_select_book mt-2"
                      aria-label="select example"
                      value={boardingPointTypeObj}
                      onChange={(e) => {
                        setBoardingPointTypeObj(e.target.value);
                      }}
                    >
                      <option value="">Select Boarding Type</option>

                      {selectedOption &&
                        JSON.parse(
                          JSON.parse(selectedOption)?.boarding_point_price[0] ||
                          "[]"
                        )?.map((boarding: any) => {
                          const data: any = "singleBoardPrice";
                          if (boarding.bp === boardingPointCity) {
                            if (Object.keys(boarding.bp_price[0]) != data) {
                              return boarding.bp_price.map(
                                (bp: any, index: number) => (
                                  <option
                                    key={index}
                                    value={JSON.stringify(bp)}
                                  >
                                    {Object.keys(bp)} ₹ {Object.values(bp)} /
                                    Person
                                  </option>
                                )
                              );
                            } else {
                              return boarding.bp_price.map(
                                (bp: any, index: number) => (
                                  <option
                                    key={index}
                                    value={JSON.stringify(bp)}
                                  >
                                    ₹{" "}
                                    {bp.singleBoardPrice
                                      ? bp.singleBoardPrice
                                      : 0}{" "}
                                    / Person
                                  </option>
                                )
                              );
                            }
                          }

                          return null;
                        })}
                    </select>

                    <div className="card mt-3 mb-3">
                      <div className="card-body p-0">
                        <h5 className="card-title text-center mt-2 mb-2">
                          Room Sharing
                        </h5>
                        <div className="package_detail px-3 d-flex justify-content-between align-items-center flex-column">
                          <div className="w-100 d-flex ">
                            <div className="detaiL-row d-flex justify-content-start align-items-center">
                              <p className="h6">Type</p>
                            </div>
                            <div className="detaiL-row d-flex justify-content-center align-items-center">
                              <p className="h6 ">Price Per Person</p>
                            </div>
                            <div className="detaiL-row d-flex justify-content-center align-items-center">
                              <i className="fa-solid fa-user fa-1x"></i>
                            </div>
                          </div>

                          {selectedOption &&
                            JSON.parse(
                              JSON.parse(selectedOption)
                                ?.roomSharing_price[0] || "[]"
                            )?.map((packageObj: any, index: number) => (
                              <div className="w-100 d-flex ">
                                <div className="detaiL-row d-flex justify-content-start align-items-center">
                                  <p className="h6">{packageObj?.roomType}</p>
                                </div>
                                <div className="detaiL-row d-flex justify-content-center align-items-center">
                                  <p className="h6">{packageObj?.roomPrice}</p>
                                </div>
                                <div className="detaiL-row d-flex justify-content-center align-items-center">
                                  <div className="for_number_trip">
                                    <div>
                                      <h5 className="d-inline-block">
                                        <span
                                          className="for_minus cursor mx-1"
                                          onClick={(e: any) =>
                                            decrementRoomTypeCount(index)
                                          }
                                        >
                                          -
                                        </span>{" "}
                                        {calculationObj.travel_roomSharing_data[
                                          index
                                        ]?.persons || 0}{" "}
                                        <span
                                          className="for_plus cursor mx-1"
                                          onClick={(e: any) =>
                                            incrementRoomTypeCount(index)
                                          }
                                        >
                                          +
                                        </span>
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                        <hr className="hr mx-2 mt-1"></hr>
                        <div className="package_detail px-3 d-flex justify-content-between align-items-center ">
                          <div className="detaiL-row d-flex justify-content-start align-items-center">
                            <p className="h6 text-nowrap">Total Persons:</p>
                          </div>
                          <div className="detaiL-row d-flex justify-content-center align-items-center"></div>
                          <div className="detaiL-row d-flex justify-content-center align-items-center">
                            <p className="h6 text-center">
                              {totalRoomSharingPersons()}
                            </p>
                          </div>
                        </div>
                        <hr className="hr mx-2 mt-1"></hr>
                      </div>
                    </div>
                    <div className="package_detail px-3 d-flex justify-content-between align-items-center ">
                      <div className="detaiL-row d-flex justify-content-start align-items-center">
                        <p className="h4 text-nowrap">Total Amount</p>
                      </div>
                      <div className="detaiL-row d-flex justify-content-center align-items-center"></div>
                      <div className="detaiL-row d-flex justify-content-center align-items-center">
                        <p className="h4 " id="totalAmount">
                          ₹{totalAmount()}
                        </p>
                      </div>
                    </div>
                    <hr className="hr mx-2 mt-1"></hr>
                    <div className="text-center">
                      <button
                        type="button"
                        onClick={proceedToCheckout}
                        className="btn btn-tour w-50 large"
                      >
                        Book my Tour
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose} centered>
          <div className="modal-header">
            <h4 className="text-center Enquriy-text">Enquiry</h4>

            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            ></button>
          </div>
          <div className="modal-body">
            <div className="row d-flex px-3 mt-3 for-enquriy">
              <div className="col-lg-6 ">
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput3"
                    className="form-label"
                  >
                    Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput3"
                    name="name"
                    placeholder="Enter Your Name"
                    style={{ border: "0.76px solid rgba(255, 169, 35, 1)" }}
                    value={formData3.name}
                    onChange={handleChange3}
                  />
                </div>
              </div>
              <div className="col-lg-6 ">
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput3"
                    className="form-label"
                  >
                    Destination<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text "
                    className="form-control"
                    id="exampleFormControlInput3"
                    name="destination"
                    placeholder="Enter Your Destination"
                    style={{ border: "0.76px solid rgba(255, 169, 35, 1)" }}
                    value={tour_name}
                    onChange={handleChange3}
                  />
                </div>
              </div>
            </div>
            <div className="row d-flex px-3">
              <div className="col-lg-6">
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput3"
                    className="form-label"
                  >
                    Number of Travellers
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    id="exampleFormControlInput3"
                    name="numberOfTraveller"
                    placeholder="Enter Number Of Travellers"
                    style={{ border: "0.76px solid rgba(255, 169, 35, 1)" }}
                    value={formData3.numberOfTraveller}
                    onChange={handleChange3}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput3"
                    className="form-label"
                  >
                    Email ID<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="email"
                    className={`form-control ${emailError ? "is-invalid" : ""}`}
                    id="exampleFormControlInput3"
                    name="email"
                    placeholder="Enter Your Email ID"
                    style={{ border: "0.76px solid rgba(255, 169, 35, 1)" }}
                    value={formData3.email}
                    onChange={handleChange3}
                  />
                  {emailError && (
                    <div className="invalid-feedback">{emailError}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="row d-flex px-3">
              <div className="col-lg-6">
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput3"
                    className="form-label"
                  >
                    Month
                  </label>
                  <select
                    className="form-select"
                    id="exampleFormControlInput3"
                    name="traveler_month"
                    style={{ border: "0.76px solid rgba(255, 169, 35, 1)" }}
                    value={formData3.traveler_month}
                    onChange={handleChange3}
                  >
                    <option value="">Select Month</option>
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                  </select>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput3"
                    className="form-label"
                  >
                    Mobile Number<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="tel"
                      className={`form-control ${mobileError ? "is-invalid" : ""
                        }`}
                      id="exampleFormControlInput3"
                      name="mobile"
                      placeholder="Enter Mobile Number"
                      style={{ border: "0.76px solid rgba(255, 169, 35, 1)" }}
                      value={formData3.mobile}
                      onChange={handleChange3}
                      onInput={(e: any) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                      maxLength={10}
                    />
                    <button
                      type="button"
                      onClick={() => sendOtp(formData3.mobile)}
                      className="btn btn-primary"
                      disabled={formData3.mobile.length !== 10}
                      style={{
                        padding: "5px 10px",
                        fontSize: "10px",
                        backgroundColor:
                          formData3.mobile.length === 10 ? "#ffa923" : "gray",
                        borderColor:
                          formData3.mobile.length === 10 ? "#ffa923" : "gray",
                        cursor:
                          formData3.mobile.length === 10
                            ? "pointer"
                            : "not-allowed",
                      }}
                    >
                      Send OTP
                    </button>
                  </div>
                  {mobileError && (
                    <div className="invalid-feedback">{mobileError}</div>
                  )}
                </div>
              </div>
              {otpVisible && (
                <div className="mt-3  w-50 m-auto">
                  <input
                    type="text"
                    className="form-control"
                    name="otp"
                    maxLength={4}

                    placeholder="Enter OTP"
                    value={formData3.otp}
                    onChange={handleChange3}
                    style={{ border: "0.76px solid rgba(255, 169, 35, 1)" }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer border-0">
            <button
              className="btn btn-color m-auto mb-2"
              style={{
                padding: "7px 37px",
                fontSize: "20px",
                borderRadius: "10px",
                background: "#ffA923",
                margin: "4px 2px",
                color: "#FFF",
              }}
              onClick={() => handleVerifyOtp(formData3.otp, 3)}
              disabled={!isFormValid3()}  // Disable button if form is not valid

            >
              Send
            </button>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default TripDetails;
