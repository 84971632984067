import React from "react";
import "./MainGeneral.css";
import { Button } from "react-bootstrap";

const MainGeneral = (props: any) => {
  // Calculate Total Amount
  const TotalAmt =
    (props?.trav?.finalPrice || 0);

  // Calculate Pending Amount
  const pendingAmt =
    props?.trav?.amountPaid < TotalAmt ? TotalAmt - props?.trav?.amountPaid : 0;

  // Calculate Add-on Paid Amount
  const addonPaidAmt =
    (props?.trav?.amountPaid || 0) - (props?.trav?.TourPrice || 0);

  // Calculate Amount Remaining
  const amountRemaining =
    props?.trav?.amountPaid -
    addonPaidAmt -
    pendingAmt -
    (props?.trav?.appliedCoupon || 0);

  // PDF URL
  const pdfUrl = `https://api.glabol.com/${props.trav?.pdf_url || ""}`;

  return (
    <>
      <form className="row g-3 mb-4 pt-4 formgeneral pb-4">
        <div className="col-md-4">
          <label className="form-label label-general">First Name</label>
          <input
            type="text"
            style={{ background: "#FFEAC9" }}
            className="form-control placegeneral "
            id="inputEmail4"
            value={props?.trav?.user_id?.firstname}
          // readOnly
          />
        </div>

        <div className="col-md-4">
          <label className="form-label label-general">Last Name</label>
          <input
            type="text"
            style={{ background: "#FFEAC9" }}
            className="form-control placegeneral"
            value={props?.trav?.user_id?.lastname || ""}
          //readOnly
          />
        </div>

        <div className="col-md-4">
          <label className="form-label label-general">Order ID</label>
          <input
            type="text"
            style={{ background: "#FFEAC9" }}
            className="form-control placegeneral"
            value={props?.trav?.order_id?.slice(0, 20) || ""}
          // readOnly
          />
        </div>

        <div className="col-md-4">
          <label className="form-label label-general">Number of Members</label>
          <input
            type="text"
            style={{ background: "#FFEAC9" }}
            className="form-control placegeneral"
            value={props?.trav?.old_travel_no_of_traveler || ""}
          // readOnly
          />
        </div>

        <div className="col-md-4">
          <label className="form-label label-general">Boarding Location</label>
          <input
            type="text"
            style={{ background: "#FFEAC9" }}
            className="form-control placegeneral"
            value={
              props?.trav?.travel_boarding_point?.boarding ??
              props?.trav?.boardingpointustomized
            }
          // readOnly
          />
        </div>

        <div className="col-md-4">
          <label className="form-label label-general">Date</label>
          <input
            type="text"
            style={{ background: "#FFEAC9" }}
            className="form-control placegeneral"
            value={props?.trav?.travel_date || ""}
          //  readOnly
          />
        </div>
      </form>

      <form className="row g-3 mb-2 pt-1 formgeneral2">
        <div className="col-md-12">
          <label className="form-label label-general2 pb-2">
            Payment Status:
            <span className="Partial-text">
              &nbsp; {props?.trav?.payment_status || "N/A"}
            </span>
          </label>
        </div>

        <div className="col-md-4">
          <label className="form-label label-general3">
            Amount Paid (Tour)
          </label>
          <input
            type="text"
            className="form-control placegeneral3"
            value={amountRemaining || 0}
            readOnly
          />
        </div>

        <div className="col-md-4">
          <label className="form-label label-general3">Pending Amount</label>
          <input
            type="text"
            className="form-control placegeneral3"
            value={Math.abs(pendingAmt || 0).toString()}
            readOnly
          />
        </div>

        <div className="col-md-4">
          <label className="form-label label-general3">Total Amount</label>
          <input
            type="text"
            className="form-control placegeneral3"
            value={TotalAmt || 0}
            readOnly
          />
        </div>

        <div className="col-md-3 pt-3 px-5 for-invoice-mobile m-auto text-center">
          <a
            href={pdfUrl}
            className="invoice-btn"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button>INVOICE</Button>
          </a>
        </div>
      </form>
    </>
  );
};

export default MainGeneral;
