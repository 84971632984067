import React from "react";
import "./featured.css";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import { useState } from "react";
import "react-multi-carousel/lib/styles.css";
import "swiper/css";
import Label2Image from "../../assets/images/Label2image.png";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import { Loader } from "../loader/Loader";
import Noimg from "../../assets/images/No-imag.jpg";
import { gtag_report_conversion, useAppDispatch, useAppSelector } from "../../hook";
import { RedirectUrlAfterLogin } from "../repeatComponent/RedirectUrlAfterLogin";
import { useNavigate } from "react-router-dom";

function Featured(props: any) {
  const location: any = useLocation();
  const tourDetails = useAppSelector(
    (state: any) => state.tour?.searchTourData
  );
  const tourDetails2 = useAppSelector(
    (state: any) => state.tour?.searchTourData2
  );
  const navigate = useNavigate();

  const featuredData = location.state;

  const [searchParams, setSearchParams] = useState<any>(
    new URLSearchParams(location.search)
  );
  const [load, setLoad] = useState<boolean>(true);
  const [searchData, setSearchData] = useState<any>(tourDetails);
  const [searchData1, setSearchData1] = useState<any>([]);
  const [isShowMore, setIsShowMore] = useState(false);
  const [featuredpackData, setFeaturedpackData] = useState<any>([]);
  const [cardsData, setCardsData] = useState<any>([]);
  const [packages, setpackages] = useState<any>([]);
  const [cardsData2, setCardsData2] = useState<any>([]);
  const [adventureData, setAdventureData] = useState<any>([]);
  const [menuData, setMenuData] = useState<any>([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const params = new URLSearchParams(location.search);
  const menumaster_id = params.get("menumaster_id");
  const adventure_type = params.get("adventure_type");
  const [tourType, setTourType] = useState<string>("");
  const [internationalorDomestic, setInternationalorDomestic] = useState<string>("");
  const [error, setError] = useState<Error | null>(null);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 600);
  const [adventData, setAdventData] = useState([]);
  const [menumasterData, setMenumasterData] = useState<any>(tourDetails2);
  const paramsfromlink = useParams();
  const [selectedAdventureType, setSelectedAdventureType] =
    useState<string>("");
  const [selectedAdventureType2, setSelectedAdventureType2] = useState("");
  const source = location?.state?.source;
  const id = location?.state?.itemid;
  const { id: linkUrl } = useParams();
  const page = location?.state?.page;
  const { pathname } = useLocation();
  const [emailError, setEmailError] = useState("");
  const dispatch = useAppDispatch();
  const [mobileError, setMobileError] = useState("");
  const [otpVisible, setOtpVisible] = useState(false);
  const [otpData, setOtpData] = useState();
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [tour_iid, setTour_id] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    numberOfTraveller: "",
    mobile: "",
    email: "",
    traveler_month: "",
    destination: "",
    destination_id: id,
    otp: ""

  });


  const resetFormData = () => {
    setFormData({
      name: "",
      numberOfTraveller: "",
      mobile: "",
      email: "",
      traveler_month: "",
      destination: "",
      destination_id: id,
      otp: ""
    });
  };
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const MAX_LINES = 2;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setFeaturedpackData([]);
    fetchData();
  }, [location]);
  useEffect(() => {
    advantour();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const isFormValid = () => {
    return Object.values(formData).every((value) => value !== "");
  };

  const handleVerifyOtp = async () => {
    if (formData.otp) {
      const response = await verifyOtp(formData.otp);

      if (response.success) {

        gtag_report_conversion();

        Swal.fire({
          title: 'Success!',
          text: 'OTP verified and enquiry sent successfully.',
          icon: 'success',
          confirmButtonText: 'OK',
        }).then(() => {
          resetFormData();
        });

      } else {
        Swal.fire({
          title: 'Invalid OTP!',
          text: response.message || 'The OTP you entered is incorrect. Please try again.',
          icon: 'error',
          confirmButtonText: 'Retry',
        });
      }
    } else {
      Swal.fire({
        title: 'Oops!',
        text: 'Please enter the OTP.',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
    }
  };

  const sendOtpEnquiry = async (mobile: string) => {
    showLoader("Sending OTP Please Wait");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/sendOtpForEnquiry`,
        { mobile }
      );
      setOtpData(response.data.otp._id);

    } catch (error) {
      console.error("Error sending OTP:", error);
      setMobileError("Failed to send OTP. Please try again.");
    }
  };
  const verifyOtp = async (otp: string): Promise<{ success: boolean; message: string }> => {
    showLoader("Sending enquiry Please Wait");

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/verifyOtpForEnquiry?id=${otpData}`,
        {
          otp: formData.otp,
          name: formData.name,
          destination_id: tour_iid,
          numberOfTraveller: formData.numberOfTraveller,
          mobile: formData.mobile,
          email: formData.email,
          traveler_month: formData.traveler_month,
        }
      );

      // Assuming the API response contains `success` and `message`
      return response.data;
    } catch (error) {
      // Handle the error and return a failure response
      return {
        success: false,
        message: 'Failed to verify OTP. Please try again.',
      };
    }
  };
  const sendOtp = async () => {
    if (formData.mobile.length === 10) {
      try {
        await sendOtpEnquiry(formData.mobile);

        // Show success message using SweetAlert
        Swal.fire({
          title: 'OTP Sent!',
          text: `An OTP has been sent to your mobile number ${formData.mobile}.`,
          icon: 'success',
          confirmButtonText: 'OK',
        });

        setOtpVisible(true);
      }
      catch (error) {

        // Show error message using SweetAlert
        Swal.fire({
          title: 'Error',
          text: 'Failed to send OTP. Please try again later.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    } else {
      setMobileError("Please enter a valid mobile number");
      setOtpVisible(false);

      // Show validation error using SweetAlert
      Swal.fire({
        title: 'Invalid Number',
        text: 'Please enter a valid 10-digit mobile number.',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
    }
  };

  const advantour = async () => {
    setLoad(true);
    try {
      const advantureResp = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/get-all-adventure`
      );
      setAdventData(advantureResp.data.data);
      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.error("Error fetching adventure data:", error);
    }
  };
  const handleAdventureTypeChange = async (selectedAdventureType: string) => {
    setSelectedAdventureType(selectedAdventureType);
    setLoad(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/feturlistbyID/${location.state.itemid}`,
        {
          adventureTypeId: [selectedAdventureType],
        }
      );
      setFormData({
        ...formData,
        destination: response.data.FeaturedData.title,
      });
      setInternationalorDomestic(response.data.domesticorInternational);

      setCardsData(response.data.FeaturedData);
      setCardsData2(response.data.data);
      setTourType(response.data.tourtype);

      setLoad(false);
    } catch (error) {
      dismissToast("");
      setLoad(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleAdventureTypeChange2 = async (selectedAdventureType2: string) => {
    setSelectedAdventureType2(selectedAdventureType2);
    setLoad(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getmenuMappingById?submenuId=${id}`,
        {
          adventureTypeId: [selectedAdventureType2],
        }
      );
      setMenuData(response.data.allData);
      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    const searchParams = source;
    if (source === "featuredTour" || props?.sourced === "featuredTour") {
      try {
        setLoad(true);
        let response: any;
        if (page != "personalized") {
          response = await axios.post(
            `${process.env.REACT_APP_API_URL}/admin/feturlistbySlugUrl`,
            {
              slug_url: linkUrl,
            }
          );
        }
        if (page == "personalized") {
          response = await axios.post(
            `${process.env.REACT_APP_API_URL}/admin/feturlistbyIDCustomize/${id}`
          );
        }
        dismissToast("");
        setFormData({
          ...formData,
          destination: response.data.FeaturedData.title,
        });
        setCardsData(response.data.FeaturedData);
        setInternationalorDomestic(response.data.domesticorInternational);

        setCardsData2(response.data.data);
        setTourType(response.data.tourtype);
        setTour_id(response.data.FeaturedData?._id)

        setLoad(false);
      } catch (error) {
        dismissToast("");
        console.error("Error fetching data:", error);
        setLoad(false);
      }
    } else if (source === "searchTour") {
      setLoad(false);
      setSearchData(tourDetails);
      setMenumasterData(tourDetails2);
    } else if (source === "menu" || props?.sourced === "menu") {
      try {
        setLoad(true);
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getmenuMappingBySlugUrl`,

          {
            slug_url: linkUrl,
          }
        );
        setFormData({
          ...formData,
          destination: response.data.FeaturedData.title,
        });
        if (response.data.success) {
          setLoad(false);
        } else {
          setLoad(false);
        }
        dismissToast("");
        setMenuData(response.data.allData);
        setInternationalorDomestic(response.data.domesticorInternational);

        setCardsData(response.data.FeaturedData);
      } catch (error) {
        dismissToast("");
        setLoad(false);
        console.error("Error fetching data:", error);
      }
    }
  };

  const Toast1 = Swal.mixin({
    toast: false,
    showConfirmButton: false,
    timer: 1,
  });

  const dismissToast = (text: any) => {
    Toast1.fire({});
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
      destination: cardsData.title,
    }));
  };
  const showLoader = (text: string) => {
    Swal.fire({
      text: text,
      allowOutsideClick: false,
      showConfirmButton: false,
      heightAuto: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  };


  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };

  return (
    <>
      <RedirectUrlAfterLogin />
      {load ? (
        <Loader />
      ) : (
        <>
          {(source === "featuredTour" || props?.sourced === "featuredTour") && (
            <>
              <div>
                <ToastContainer
                  position="top-center"
                  autoClose={5000}
                  hideProgressBar={true}
                  newestOnTop
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  toastStyle={{ top: "50%", transform: "translateY(-50%)" }}
                />

                <div className="personalized_img">
                  <img
                    src={`${process.env.REACT_APP_API_URL}/${cardsData?.image?.[0]?.src}`}
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      if (target) {
                        target.src = Noimg;
                      }
                    }}
                    className="img-fluid for_featuredimg"
                  />

                  <div className="for_featured_under">
                    <p className="for_personalizedtext">{cardsData?.title}</p>
                    <h5 className="mt-3 sunclass ">
                      {cardsData?.tagline ? (
                        <span className="for_secondtext2 mt-3">
                          {cardsData.tagline.split(" ").slice(0, 4).join(" ")}
                        </span>
                      ) : null}
                    </h5>
                  </div>

                  <div className="container">
                    <div
                      style={{ marginTop: "-100px" }}
                      className="for-mobile-enquriy-form"
                    >
                      <div className="diva"></div>
                      <div className="container finalWidthfeature">
                        <div className="form-container">
                          <div className="row mt-2 justify-content-center p-3 for_smalldevice">
                            <h3
                              style={{ fontWeight: "700" }}
                              className="text-center perfecttrip"
                            >
                              Not sure what to do? We’ll give you a Call back
                            </h3>
                            <div className="col-12 col-lg-4 col-md-12 p-2">
                              <input
                                type="text"
                                className="form-control placeholderclass"
                                placeholder="Enter your name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="col-12 col-lg-4 col-md-12 p-2">
                              <input
                                type="text"
                                className="form-control placeholderclass"
                                placeholder="Enter your mail id"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="col-12 col-lg-4 col-md-12 p-2">
                              <input
                                type="text"
                                className="form-control placeholderclass"
                                placeholder="Number of travellers"
                                name="numberOfTraveller"
                                value={formData.numberOfTraveller}
                                onChange={handleChange}
                              />
                            </div>

                            <div className="col-12 col-lg-4 col-md-12 p-2">
                              <input
                                type="text"
                                className="form-control placeholderclass"
                                placeholder="Enter Your Destination"
                                name="destination"
                                value={formData.destination}
                                onChange={handleChange}
                                readOnly
                              />
                            </div>
                            <div className="col-12 col-lg-4 col-md-12 p-2">
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control placeholderclass"
                                  placeholder="Enter your phone number"
                                  name="mobile"
                                  onInput={(e: any) => {
                                    e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
                                  }}
                                  maxLength={10}
                                  value={formData.mobile}
                                  onChange={handleChange}
                                />
                                <button
                                  type="button"
                                  onClick={sendOtp}
                                  className="btn btn-primary"
                                  disabled={formData.mobile.length !== 10}
                                  style={{
                                    padding: "5px 10px",
                                    fontSize: "10px",
                                    backgroundColor: formData.mobile.length === 10 ? "#ffa923" : "gray",
                                    borderColor: formData.mobile.length === 10 ? "#ffa923" : "gray",
                                    cursor: formData.mobile.length === 10 ? "pointer" : "not-allowed",
                                  }}
                                >
                                  Send OTP
                                </button>
                              </div>
                              {otpVisible && (
                                <div className="mt-3 w-50 m-auto">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="otp"
                                    placeholder="Enter OTP"
                                    value={formData.otp}
                                    maxLength={4}

                                    onChange={handleChange}
                                    style={{ border: "0.76px solid rgba(255, 169, 35, 1)" }}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="col-12 col-lg-4 col-md-12 p-2">
                              <select
                                className="form-control placeholderclass form-select"
                                name="traveler_month"
                                value={formData.traveler_month}
                                onChange={handleChange}
                              >
                                <option value="">Select Month</option>
                                <option value="January">January</option>
                                <option value="February">February</option>
                                <option value="March">March</option>
                                <option value="April">April</option>
                                <option value="May">May</option>
                                <option value="June">June</option>
                                <option value="July">July</option>
                                <option value="August">August</option>
                                <option value="September">September</option>
                                <option value="October">October</option>
                                <option value="November">November</option>
                                <option value="December">December</option>
                              </select>
                            </div>
                            <div className="col-12 col-lg-6 col-md-12 mt-2 featuresubmit">
                              <button
                                className="for_btn"
                                style={{
                                  backgroundColor: isFormValid() ? "#FFA923" : "gray",
                                  cursor: isFormValid() ? "pointer" : "not-allowed",
                                }}
                                onClick={handleVerifyOtp}
                                disabled={!isFormValid()}                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-container">
                          <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="headingOne">
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseOne"
                                  aria-expanded="false"
                                  aria-controls="collapseOne"
                                >
                                  Not sure what to do? We’ll give you a Call back
                                </button>
                              </h2>
                              <div
                                id="collapseOne"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample"
                              >
                                <div className="accordion-body">
                                  <input
                                    type="text"
                                    className="form-control placeholderclass"
                                    placeholder="Enter your name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                  />
                                  <input
                                    type="text"
                                    className="form-control placeholderclass"
                                    placeholder="Enter your mail id"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                  />
                                  <input
                                    type="text"
                                    className="form-control placeholderclass"
                                    placeholder="Number of travellers"
                                    name="numberOfTraveller"
                                    value={formData.numberOfTraveller}
                                    onChange={handleChange}
                                  />

                                  <input
                                    type="text"
                                    className="form-control placeholderclass"
                                    placeholder="Enter Your Destination"
                                    name="destination"
                                    value={formData.destination}
                                    onChange={handleChange}
                                    readOnly
                                  />
                                  <select
                                    className="form-control placeholderclass form-select"
                                    name="traveler_month"
                                    value={formData.traveler_month}
                                    onChange={handleChange}
                                  >
                                    <option value="">Select Month</option>
                                    <option value="January">January</option>
                                    <option value="February">February</option>
                                    <option value="March">March</option>
                                    <option value="April">April</option>
                                    <option value="May">May</option>
                                    <option value="June">June</option>
                                    <option value="July">July</option>
                                    <option value="August">August</option>
                                    <option value="September">September</option>
                                    <option value="October">October</option>
                                    <option value="November">November</option>
                                    <option value="December">December</option>
                                  </select>
                                  <div style={{ position: "relative", width: "100%" }}>
                                    <input
                                      type="text"
                                      className="form-control placeholderclass"
                                      placeholder="Enter your phone number"
                                      name="mobile"
                                      onInput={(e: any) => {
                                        e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
                                      }}
                                      maxLength={10}
                                      value={formData.mobile}
                                      onChange={handleChange}
                                      style={{ paddingRight: "100px" }} // Adjust padding to make space for the button
                                    />
                                    <button
                                      type="button"
                                      onClick={sendOtp}
                                      className="btn btn-primary"
                                      disabled={formData.mobile.length !== 10}
                                      style={{
                                        position: "absolute",
                                        right: "10px", // Adjust for spacing
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        padding: "5px 10px",
                                        fontSize: "10px",
                                        backgroundColor: formData.mobile.length === 10 ? "#ffa923" : "gray",
                                        borderColor: formData.mobile.length === 10 ? "#ffa923" : "gray",
                                        cursor: formData.mobile.length === 10 ? "pointer" : "not-allowed",
                                      }}
                                    >
                                      Send OTP
                                    </button>
                                    {otpVisible && (
                                      <div
                                        className="mt-3 w-50 m-auto"
                                        style={{
                                          position: "absolute",
                                          top: "100%",
                                          left: "50%",
                                          transform: "translateX(-50%)",
                                          marginTop: "10px", // Space between fields
                                        }}
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="otp"
                                          placeholder="Enter OTP"
                                          value={formData.otp}
                                          maxLength={4}
                                          onChange={handleChange}
                                          style={{ border: "0.76px solid rgba(255, 169, 35, 1)" }}
                                        />
                                      </div>
                                    )}
                                  </div>

                                  <button
                                    className="for_btn mt-5"
                                    style={{
                                      position: "relative",
                                      top: "100%",
                                      left: "50%",
                                      transform: "translateX(-50%)",
                                      marginTop: "10px",
                                      backgroundColor: isFormValid() ? "#FFA923" : "gray",
                                      cursor: isFormValid() ? "pointer" : "not-allowed",
                                    }}
                                    onClick={handleVerifyOtp}
                                    disabled={!isFormValid()}
                                  >
                                    Submit
                                  </button>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="divc2"></div>
                    </div>
                  </div>
                </div>
                <div className="container mt-4">
                  <div>
                    <div className="card for_cardborder">
                        <h3 className="text-center pt-3 undercard_international">
                          <span style={{ color: "rgba(220, 27, 94, 1)" }}>
                            About
                          </span>{" "}
                          {internationalorDomestic.charAt(0).toUpperCase() + internationalorDomestic.slice(1)}{" "}
                          Packages
                        </h3>

                      <div className="undercardbest">
                        <div
                          className={isExpanded ? "" : "collapseddd"}
                          dangerouslySetInnerHTML={{
                            __html: cardsData?.aboutus,
                          }}
                        ></div>

                        {/* Show Read More/Read Less button if the content is longer */}
                        {cardsData?.aboutus?.length > MAX_LINES && (
                          <h1 onClick={toggleExpand} className="for_rl">
                            {isExpanded ? "Read Less" : "Read More"}
                          </h1>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div></div>

                <div className="container my-3">
                  {/* <div className="d-flex justify-content-center align-items-center">
                    <div className=" hr_featured mb-2 mt-5"></div>
                  </div> */}
                  <h1 className="text-center for_backpacking mt-4">
                    <span className="pink-text">Featured </span>
                    <span className="black-text"> Packages</span>
                  </h1>
                  <div className="container center">
                    <div className="w-25 mt-3 slct">
                      <select
                        id="inputState"
                        className="form-select for_Adventuretype"
                        style={{ border: "0.5px solid grey" }}
                        aria-placeholder="select option"
                        onChange={(e) =>
                          handleAdventureTypeChange(e.target.value)
                        }
                        value={selectedAdventureType}
                      >
                        <option value="" disabled selected>
                          Select Adventure Type
                        </option>
                        {adventData.map((opts: any, i: number) => (
                          <option value={opts._id} key={i}>
                            {opts.adventure_type}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row mt-4">
                    {Array.isArray(cardsData2) &&
                      cardsData2?.map((card: any, index: any) => (
                        <div
                          key={index}
                          className="col-lg-4 col-md-4 col-sm-12 col-12 mb-2 mt-4 for_mobilefeatured"
                        >
                          {/* <Link
                            to={`/tripdetails/${card._id}`}
                            className="featuredlink"
                          > */}
                          <div
                            className="card border-0 for_card_inter mb-1"
                            onClick={() => {
                              const id = card._id;
                              const slugUrl = card?.title
                                ?.toLowerCase() // Convert to lowercase
                                .replace(/\s+/g, "-") // Replace spaces with hyphens
                                .replace(/\//g, "-") // Replace slashes with hyphens
                                .replace(/\((.*?)\)/g, "$1"); // Remove parentheses but keep the content inside

                              navigate(`/tripdetails/${slugUrl}`, {
                                state: id,
                              });
                            }}
                          >
                            <img
                              src={`${process.env.REACT_APP_API_URL}/${card.tour_image.length
                                ? card?.tour_image[0].src
                                : ""
                                }`}
                              alt="Featured Package"
                              onError={(e) => {
                                const target = e.target as HTMLImageElement;
                                if (target) {
                                  target.src = Noimg;
                                }
                              }}
                              className=" card-img-top-inter2 card-img2"
                            />

                            <div className="for_visitdays mb-4">
                              <h5 className="for_h5">
                                {card.tourtype === "normal"
                                  ? "Group"
                                  : card.tourtype}
                              </h5>
                            </div>
                            <div className="for-sold-out2 mb-4">
                              {card?.tagLabelNames[0] ? (
                                <>
                                  <img
                                    src={Label2Image}
                                    className="img-fluiddd"
                                  />
                                  <div className="LabelName">
                                    {card?.tagLabelNames[0]}
                                  </div>
                                </>
                              ) : null}
                            </div>

                            <div className="dayandnightw">
                              <p>
                                {card.nightorday}N/{card.nightorday + 1}D
                              </p>
                            </div>
                            <div className="card-body">
                              <div className="card-text">
                                <div className="main_para justify-content-between">
                                  <h5 className="loremforfeatured">
                                    {card.title}
                                  </h5>
                                  {card.location &&
                                    card.location !== "undefined" && (
                                      <p className="fetured_para">
                                        {
                                          card.location
                                          // .split(" ")
                                          // .slice(0, 4)
                                          // .join(" ")
                                        }
                                      </p>
                                    )}
                                </div>
                                <div className="d-flex justify-content-between align-items-baseline mt-2">
                                  {card.packageType_price &&
                                    card.packageType_price !== "undefined" && (
                                      <p className=" startrate ">
                                        {card.tourtype === "normal"
                                          ? `Start from ₹${card?.packageType_price}`
                                          : "Customizable Price"}
                                      </p>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* </Link> */}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </>
          )}
          {source === "searchTour" && (
            <>
              <div>
                <ToastContainer
                  position="top-center"
                  autoClose={5000}
                  hideProgressBar={true}
                  newestOnTop
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  toastStyle={{ top: "50%", transform: "translateY(-50%)" }}
                />

                <div className="personalized_img">
                  <img
                    src={`${process.env.REACT_APP_API_URL}/${searchData[0]?.banner_image?.[0]?.src}`}
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      if (target) {
                        target.src = Noimg;
                      }
                    }}
                    className="img-fluid for_featuredimg"
                  />
                  <div className="for_featured_under">
                    <p className="for_personalizedtext">
                      {menumasterData?.title}
                    </p>
                    <h5 className="mt-3 sunclass ">
                      {menumasterData?.tagline ? (
                        <span className="for_secondtext2 mt-3">
                          {menumasterData?.tagline
                            .split(" ")
                            .slice(0, 4)
                            .join(" ") || "hello"}
                        </span>
                      ) : (
                        <span className="for_secondtext2 mt-3">
                          All Destination
                        </span>
                      )}
                    </h5>
                  </div>
                  <div className="container">
                    <div
                      style={{ marginTop: "-100px" }}
                      className="for-mobile-enquriy-form"
                    >
                      <div className="diva"></div>
                      <div className="container finalWidthfeature">
                        <div className="form-container">
                          <div className="row mt-2 justify-content-center p-3 for_smalldevice">
                            <h3
                              style={{ fontWeight: "700" }}
                              className="text-center perfecttrip"
                            >
                              Not sure what to do? We’ll give you a Call back
                            </h3>
                            <div className="col-12 col-lg-4 col-md-12 p-2">
                              <input
                                type="text"
                                className="form-control placeholderclass"
                                placeholder="Enter your name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="col-12 col-lg-4 col-md-12 p-2">
                              <input
                                type="text"
                                className="form-control placeholderclass"
                                placeholder="Enter your mail id"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="col-12 col-lg-4 col-md-12 p-2">
                              <input
                                type="text"
                                className="form-control placeholderclass"
                                placeholder="Number of travellers"
                                name="numberOfTraveller"
                                value={formData.numberOfTraveller}
                                onChange={handleChange}
                              />
                            </div>


                            <div className="col-12 col-lg-4 col-md-12 p-2">
                              <input
                                type="text"
                                className="form-control placeholderclass"
                                placeholder="Enter Your Destination"
                                name="destination"
                                value={formData.destination}
                                onChange={handleChange}
                                readOnly
                              />
                            </div> <div className="col-12 col-lg-4 col-md-12 p-2">
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control placeholderclass"
                                  placeholder="Enter your phone number"
                                  name="mobile"
                                  onInput={(e: any) => {
                                    e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
                                  }}
                                  maxLength={10}
                                  value={formData.mobile}
                                  onChange={handleChange}
                                />
                                <button
                                  type="button"
                                  onClick={sendOtp}
                                  className="btn btn-primary"
                                  disabled={formData.mobile.length !== 10}
                                  style={{
                                    padding: "5px 10px",
                                    fontSize: "10px",
                                    backgroundColor: formData.mobile.length === 10 ? "#ffa923" : "gray",
                                    borderColor: formData.mobile.length === 10 ? "#ffa923" : "gray",
                                    cursor: formData.mobile.length === 10 ? "pointer" : "not-allowed",
                                  }}
                                >
                                  Send OTP
                                </button>
                              </div>
                              {otpVisible && (
                                <div className="mt-3 w-50 m-auto">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="otp"
                                    placeholder="Enter OTP"
                                    value={formData.otp}
                                    maxLength={4}

                                    onChange={handleChange}
                                    style={{ border: "0.76px solid rgba(255, 169, 35, 1)" }}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="col-12 col-lg-4 col-md-12 p-2">
                              <select
                                className="form-control placeholderclass form-select"
                                name="traveler_month"
                                value={formData.traveler_month}
                                onChange={handleChange}
                              >
                                <option value="">Select Month</option>
                                <option value="January">January</option>
                                <option value="February">February</option>
                                <option value="March">March</option>
                                <option value="April">April</option>
                                <option value="May">May</option>
                                <option value="June">June</option>
                                <option value="July">July</option>
                                <option value="August">August</option>
                                <option value="September">September</option>
                                <option value="October">October</option>
                                <option value="November">November</option>
                                <option value="December">December</option>
                              </select>
                            </div>
                            <div className="col-12 col-lg-6 col-md-12 mt-2 featuresubmit">
                              <button
                                className="for_btn"
                                style={{
                                  backgroundColor: isFormValid() ? "#FFA923" : "gray",
                                  cursor: isFormValid() ? "pointer" : "not-allowed",
                                }}
                                onClick={handleVerifyOtp}
                                disabled={!isFormValid()}                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-container">
                          <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="headingOne">
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseOne"
                                  aria-expanded="false"
                                  aria-controls="collapseOne"
                                >
                                  Not sure what to do? We’ll give you a Call
                                  back
                                </button>
                              </h2>
                              <div
                                id="collapseOne"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample"
                              >
                                <div className="accordion-body">
                                  <input
                                    type="text"
                                    className="form-control placeholderclass"
                                    placeholder="Enter your name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                  />
                                  <input
                                    type="text"
                                    className="form-control placeholderclass"
                                    placeholder="Enter your mail id"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                  />
                                  <input
                                    type="text"
                                    className="form-control placeholderclass"
                                    placeholder="Number of travellers"
                                    name="numberOfTraveller"
                                    value={formData.numberOfTraveller}
                                    onChange={handleChange}
                                  />
                                  <input
                                    type="text"
                                    className="form-control placeholderclass"
                                    placeholder="Enter Your Destination"
                                    name="destination"
                                    value={formData.destination}
                                    onChange={handleChange}
                                    readOnly
                                  />
                                  <select
                                    className="form-control placeholderclass form-select"
                                    name="traveler_month"
                                    value={formData.traveler_month}
                                    onChange={handleChange}
                                  >
                                    <option value="">Select Month</option>
                                    <option value="January">January</option>
                                    <option value="February">February</option>
                                    <option value="March">March</option>

                                    <option value="April">April</option>
                                    <option value="May">May</option>
                                    <option value="June">June</option>
                                    <option value="July">July</option>
                                    <option value="August">August</option>
                                    <option value="September">September</option>
                                    <option value="October">October</option>
                                    <option value="November">November</option>
                                    <option value="December">December</option>
                                  </select>
                                  <div style={{ position: "relative", width: "100%" }}>
                                    <input
                                      type="text"
                                      className="form-control placeholderclass"
                                      placeholder="Enter your phone number"
                                      name="mobile"
                                      onInput={(e: any) => {
                                        e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
                                      }}
                                      maxLength={10}
                                      value={formData.mobile}
                                      onChange={handleChange}
                                      style={{ paddingRight: "100px" }} // Adjust padding to make space for the button
                                    />
                                    <button
                                      type="button"
                                      onClick={sendOtp}
                                      className="btn btn-primary"
                                      disabled={formData.mobile.length !== 10}
                                      style={{
                                        position: "absolute",
                                        right: "10px", // Adjust for spacing
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        padding: "5px 10px",
                                        fontSize: "10px",
                                        backgroundColor: formData.mobile.length === 10 ? "#ffa923" : "gray",
                                        borderColor: formData.mobile.length === 10 ? "#ffa923" : "gray",
                                        cursor: formData.mobile.length === 10 ? "pointer" : "not-allowed",
                                      }}
                                    >
                                      Send OTP
                                    </button>
                                    {otpVisible && (
                                      <div
                                        className="mt-3 w-50 m-auto"
                                        style={{
                                          position: "absolute",
                                          top: "100%",
                                          left: "50%",
                                          transform: "translateX(-50%)",
                                          marginTop: "10px", // Space between fields
                                        }}
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="otp"
                                          placeholder="Enter OTP"
                                          value={formData.otp}
                                          maxLength={4}

                                          onChange={handleChange}
                                          style={{ border: "0.76px solid rgba(255, 169, 35, 1)" }}
                                        />
                                      </div>
                                    )}
                                  </div>

                                  <button
                                    className="for_btn mt-5"
                                    style={{
                                      position: "relative",
                                      top: "100%",
                                      left: "50%",
                                      transform: "translateX(-50%)",
                                      marginTop: "10px",
                                      backgroundColor: isFormValid() ? "#FFA923" : "gray",
                                      cursor: isFormValid() ? "pointer" : "not-allowed",
                                    }}
                                    onClick={handleVerifyOtp}
                                    disabled={!isFormValid()}
                                  >
                                    Submit
                                  </button>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="divc2"></div>
                    </div>
                  </div>
                </div>

                <div className="container mt-4">
                  {menumasterData?.aboutus ? (
                    <div>
                      <div className="card for_cardborder">
                        <h3 className="text-center pt-3 undercard_international">
                          <span style={{ color: "rgba(220, 27, 94, 1)" }}>
                            About
                          </span>{" "}
                            {internationalorDomestic.charAt(0).toUpperCase() + internationalorDomestic.slice(1)}{" "}

                          Packages
                        </h3>
                        <div className="undercardbest">
                          <div
                            className={isExpanded ? "" : "collapseddd"}
                            dangerouslySetInnerHTML={{
                              __html: cardsData?.aboutus,
                            }}
                          ></div>

                          {/* Show Read More/Read Less button if the content is longer */}
                          {cardsData?.aboutus?.length > MAX_LINES && (
                            <h1 onClick={toggleExpand} className="for_rl">
                              {isExpanded ? "Read Less" : "Read More"}
                            </h1>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="container my-3">
                  <div className="row mt-4">
                    <div className=" hr_featured mb-2"></div>

                    <h1 className="text-center for_backpacking mt-4">
                      <span className="pink-text">Featured </span>
                      <span className="black-text"> Packages</span>
                    </h1>
                    <div className="container center">
                      <div className="w-25 mt-3 slct">
                        <select
                          id="inputState"
                          className="form-select for_Adventuretype"
                          style={{ border: "0.5px solid grey" }}
                          aria-placeholder="select option"
                          onChange={(e) =>
                            handleAdventureTypeChange(e.target.value)
                          }
                          value={selectedAdventureType}
                        >
                          <option value="" disabled selected>
                            Select Adventure Type
                          </option>
                          {adventData.map((opts: any, i: number) => (
                            <option value={opts._id}>
                              {opts.adventure_type}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {Array.isArray(searchData) &&
                      searchData?.map((card: any, index: any) => (
                        <div
                          key={index}
                          className="col-lg-4 col-md-4 col-sm-12 col-12 mb-2 mt-4 for_mobilefeatured"
                        >
                          {/* <Link
                            to={`/tripdetails/${card._id}`}
                            className="featuredlink"
                          > */}
                          <div
                            className="card border-0 for_card_inter mb-1"
                            onClick={() => {
                              const id = card._id;
                              const slugUrl = card?.title
                                ?.toLowerCase() // Convert to lowercase
                                .replace(/\s+/g, "-") // Replace spaces with hyphens
                                .replace(/\//g, "-") // Replace slashes with hyphens
                                .replace(/\((.*?)\)/g, "$1"); // Remove parentheses but keep the content inside

                              navigate(`/tripdetails/${slugUrl}`, {
                                state: id,
                              });
                            }}
                          >
                            <img
                              src={`${process.env.REACT_APP_API_URL}/${card.tour_image.length
                                ? card?.tour_image[0].src
                                : ""
                                }`}
                              alt="Featured Package"
                              onError={(e) => {
                                const target = e.target as HTMLImageElement;
                                if (target) {
                                  target.src = Noimg;
                                }
                              }}
                              className=" card-img-top-inter2 card-img2"
                            />
                            <div className="for_visitdays mb-4">
                              <h5 className="for_h5">
                                {card.tourtype === "normal"
                                  ? "Group"
                                  : card.tourtype}
                              </h5>
                            </div>
                            <div className="for-sold-out2 mb-4">
                              {card?.tagLabelNames[0] ? (
                                <>
                                  <img
                                    src={Label2Image}
                                    className="img-fluiddd"
                                  />
                                  <div className="LabelName">
                                    {card?.tagLabelNames[0]}
                                  </div>
                                </>
                              ) : null}
                            </div>

                            <div className="dayandnightw">
                              <p>
                                {card.nightorday}N/{card.nightorday + 1}D
                              </p>
                            </div>
                            <div className="card-body">
                              <div className="card-text">
                                <div className="main_para justify-content-between">
                                  <h5 className="loremforfeatured">
                                    {card.title}
                                  </h5>
                                  {card.location &&
                                    card.location !== "undefined" && (
                                      <p className="fetured_para">
                                        {
                                          card.location
                                          // .split(" ")
                                          // .slice(0, 4)
                                          // .join(" ")
                                        }
                                      </p>
                                    )}
                                </div>
                                <div className="d-flex justify-content-between align-items-baseline mt-2">
                                  {card.packageType_price &&
                                    card.packageType_price !== "undefined" && (
                                      <p className=" startrate ">
                                        {card.tourtype === "normal"
                                          ? `Start from ₹${card?.packageType_price}`
                                          : "Customizable Price"}
                                      </p>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* </Link> */}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </>
          )}
          {(source === "menu" || props?.sourced === "menu") && (
            <>
              <div>
                <ToastContainer
                  position="top-center"
                  autoClose={5000}
                  hideProgressBar={true}
                  newestOnTop
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  toastStyle={{ top: "50%", transform: "translateY(-50%)" }}
                />

                <div className="personalized_img">
                  <img
                    src={`${process.env.REACT_APP_API_URL}/${cardsData?.image?.[0]?.src}`}
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      if (target) {
                        target.src = Noimg;
                      }
                    }}
                    className="img-fluid for_featuredimg"
                  />
                  <div className="for_featured_under">
                    <p className="for_personalizedtext">{cardsData?.title}</p>
                    <h5 className="mt-3 sunclass ">
                      {cardsData?.tagline ? (
                        <span className="for_secondtext2 mt-3">
                          {cardsData.tagline.split(" ").slice(0, 4).join(" ")}
                        </span>
                      ) : null}
                    </h5>
                  </div>
                  <div className="container">
                    <div
                      style={{ marginTop: "-100px" }}
                      className="for-mobile-enquriy-form"
                    >
                      <div className="diva"></div>
                      <div className="container finalWidthfeature">
                        <div className="form-container">
                          <div className="row mt-2 justify-content-center p-3 for_smalldevice">
                            <h3
                              style={{ fontWeight: "700" }}
                              className="text-center perfecttrip"
                            >
                              Not sure what to do? We’ll give you a Call back
                            </h3>
                            <div className="col-12 col-lg-4 col-md-12 p-2">
                              <input
                                type="text"
                                className="form-control placeholderclass"
                                placeholder="Enter your name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="col-12 col-lg-4 col-md-12 p-2">
                              <input
                                type="text"
                                className="form-control placeholderclass"
                                placeholder="Enter your mail id"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="col-12 col-lg-4 col-md-12 p-2">
                              <input
                                type="text"
                                className="form-control placeholderclass"
                                placeholder="Number of travellers"
                                name="numberOfTraveller"
                                value={formData.numberOfTraveller}
                                onChange={handleChange}
                              />
                            </div>


                            <div className="col-12 col-lg-4 col-md-12 p-2">
                              <input
                                type="text"
                                className="form-control placeholderclass"
                                placeholder="Enter Your Destination"
                                name="destination"
                                value={formData.destination}
                                onChange={handleChange}
                                readOnly
                              />
                            </div>        <div className="col-12 col-lg-4 col-md-12 p-2">
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control placeholderclass"
                                  placeholder="Enter your phone number"
                                  onInput={(e: any) => {
                                    e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
                                  }}
                                  maxLength={10}
                                  name="mobile"
                                  value={formData.mobile}
                                  onChange={handleChange}
                                />
                                <button
                                  type="button"
                                  onClick={sendOtp}
                                  className="btn btn-primary"
                                  disabled={formData.mobile.length !== 10}
                                  style={{
                                    padding: "5px 10px",
                                    fontSize: "10px",
                                    backgroundColor: formData.mobile.length === 10 ? "#ffa923" : "gray",
                                    borderColor: formData.mobile.length === 10 ? "#ffa923" : "gray",
                                    cursor: formData.mobile.length === 10 ? "pointer" : "not-allowed",
                                  }}
                                >
                                  Send OTP
                                </button>
                              </div>
                              {otpVisible && (
                                <div className="mt-3 w-50 m-auto">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="otp"
                                    placeholder="Enter OTP"
                                    value={formData.otp}
                                    maxLength={4}

                                    onChange={handleChange}
                                    style={{ border: "0.76px solid rgba(255, 169, 35, 1)" }}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="col-12 col-lg-4 col-md-12 p-2">
                              <select
                                className="form-control placeholderclass form-select"
                                name="traveler_month"
                                value={formData.traveler_month}
                                onChange={handleChange}
                              >
                                <option value="">Select Month</option>
                                <option value="January">January</option>
                                <option value="February">February</option>
                                <option value="March">March</option>
                                <option value="April">April</option>
                                <option value="May">May</option>
                                <option value="June">June</option>
                                <option value="July">July</option>
                                <option value="August">August</option>
                                <option value="September">September</option>
                                <option value="October">October</option>
                                <option value="November">November</option>
                                <option value="December">December</option>
                              </select>
                            </div>
                            <div className="col-12 col-lg-6 col-md-12 mt-2 featuresubmit">
                              <button
                                className="for_btn"
                                style={{
                                  backgroundColor: isFormValid() ? "#FFA923" : "gray",
                                  cursor: isFormValid() ? "pointer" : "not-allowed",
                                }}
                                onClick={handleVerifyOtp}
                                disabled={!isFormValid()}                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-container">
                          <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="headingOne">
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseOne"
                                  aria-expanded="false"
                                  aria-controls="collapseOne"
                                >
                                  Not sure what to do? We’ll give you a Call
                                  back
                                </button>
                              </h2>
                              <div
                                id="collapseOne"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample"
                              >
                                <div className="accordion-body">
                                  <input
                                    type="text"
                                    className="form-control placeholderclass"
                                    placeholder="Enter your name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                  />
                                  <input
                                    type="text"
                                    className="form-control placeholderclass"
                                    placeholder="Enter your mail id"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                  />
                                  <input
                                    type="text"
                                    className="form-control placeholderclass"
                                    placeholder="Number of travellers"
                                    name="numberOfTraveller"
                                    value={formData.numberOfTraveller}
                                    onChange={handleChange}
                                  />


                                  <input
                                    type="text"
                                    className="form-control placeholderclass"
                                    placeholder="Enter Your Destination"
                                    name="destination"
                                    value={formData.destination}
                                    onChange={handleChange}
                                    readOnly
                                  />
                                  <select
                                    className="form-control placeholderclass form-select"
                                    name="traveler_month"
                                    value={formData.traveler_month}
                                    onChange={handleChange}
                                  >
                                    <option value="">Select Month</option>
                                    <option value="January">January</option>
                                    <option value="February">February</option>
                                    <option value="March">March</option>
                                    <option value="April">April</option>
                                    <option value="May">May</option>
                                    <option value="June">June</option>
                                    <option value="July">July</option>
                                    <option value="August">August</option>
                                    <option value="September">September</option>
                                    <option value="October">October</option>
                                    <option value="November">November</option>
                                    <option value="December">December</option>
                                  </select>
                                  <div style={{ position: "relative", width: "100%" }}>
                                    <input
                                      type="text"
                                      className="form-control placeholderclass"
                                      placeholder="Enter your phone number"
                                      name="mobile"
                                      value={formData.mobile}
                                      onInput={(e: any) => {
                                        e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
                                      }}
                                      maxLength={10}
                                      onChange={handleChange}
                                      style={{ paddingRight: "100px" }} // Adjust padding to make space for the button
                                    />
                                    <button
                                      type="button"
                                      onClick={sendOtp}
                                      className="btn btn-primary"
                                      disabled={formData.mobile.length !== 10}
                                      style={{
                                        position: "absolute",
                                        right: "10px", // Adjust for spacing
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        padding: "5px 10px",
                                        fontSize: "10px",
                                        backgroundColor: formData.mobile.length === 10 ? "#ffa923" : "gray",
                                        borderColor: formData.mobile.length === 10 ? "#ffa923" : "gray",
                                        cursor: formData.mobile.length === 10 ? "pointer" : "not-allowed",
                                      }}
                                    >
                                      Send OTP
                                    </button>
                                    {otpVisible && (
                                      <div
                                        className="mt-3 w-50 m-auto"
                                        style={{
                                          position: "absolute",
                                          top: "100%",
                                          left: "50%",
                                          transform: "translateX(-50%)",
                                          marginTop: "10px", // Space between fields
                                        }}
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="otp"
                                          placeholder="Enter OTP"
                                          value={formData.otp}
                                          maxLength={4}

                                          onChange={handleChange}
                                          style={{ border: "0.76px solid rgba(255, 169, 35, 1)" }}
                                        />
                                      </div>
                                    )}
                                  </div>

                                  <button
                                    className="for_btn mt-5"
                                    style={{
                                      position: "relative",
                                      top: "100%",
                                      left: "50%",
                                      transform: "translateX(-50%)",
                                      marginTop: "10px", // Space between fields
                                      backgroundColor: isFormValid() ? "#FFA923" : "gray",
                                      cursor: isFormValid() ? "pointer" : "not-allowed",
                                    }}
                                    onClick={handleVerifyOtp}
                                    disabled={!isFormValid()}
                                  >
                                    Submit
                                  </button>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="divc2"></div>
                    </div>
                  </div>
                </div>
                <div className="container mt-4">
                  <div>
                    <div className="card for_cardborder">
                      <h3 className="text-center pt-3 undercard_international">
                        <span style={{ color: "rgba(220, 27, 94, 1)" }}>
                          About
                        </span>{" "}
                          {internationalorDomestic.charAt(0).toUpperCase() + internationalorDomestic.slice(1)}{" "}

                        Packages
                      </h3>
                      <div className="undercardbest">
                        <div
                          className={isExpanded ? "" : "collapseddd"}
                          dangerouslySetInnerHTML={{
                            __html: cardsData?.aboutus,
                          }}
                        ></div>

                        {/* Show Read More/Read Less button if the content is longer */}
                        {cardsData?.aboutus?.length > MAX_LINES && (
                          <h1 onClick={toggleExpand} className="for_rl">
                            {isExpanded ? "Read Less" : "Read More"}
                          </h1>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container my-3">
                  <div className="row mt-4">
                    <div className=" hr_featured mb-2"></div>

                    <h1 className="text-center for_backpacking mt-4">
                      <span className="pink-text">Featured </span>
                      <span className="black-text"> Packages</span>
                    </h1>
                    <div className="container center">
                      <div className="w-25 mt-3 slct">
                        <select
                          id="inputState"
                          className="form-select for_Adventuretype"
                          style={{ border: "0.5px solid grey" }}
                          aria-placeholder="select option"
                          onChange={(e) =>
                            handleAdventureTypeChange2(e.target.value)
                          }
                          value={selectedAdventureType2}
                        >
                          <option value="" disabled selected>
                            Select Adventure Type
                          </option>
                          {adventData.map((opts: any, i: number) => (
                            <option value={opts._id}>
                              {opts.adventure_type}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {Array.isArray(menuData) &&
                      menuData?.map((card: any, index: any) => (
                        <div
                          key={index}
                          className="col-lg-4 col-md-4 col-sm-12 col-12 mb-2 mt-4"
                        >
                          {/* <Link
                            to={`/tripdetails/${card._id}`}
                            className="featuredlink"
                          > */}
                          <div
                            className="card border-0 for_card_inter mb-1"
                            onClick={() => {
                              const id = card._id;
                              const slugUrl = card?.title
                                ?.toLowerCase() // Convert to lowercase
                                .replace(/\s+/g, "-") // Replace spaces with hyphens
                                .replace(/\//g, "-") // Replace slashes with hyphens
                                .replace(/\((.*?)\)/g, "$1"); // Remove parentheses but keep the content inside

                              navigate(`/tripdetails/${slugUrl}`, {
                                state: id,
                              });
                            }}
                          >
                            <img
                              src={`${process.env.REACT_APP_API_URL}/${card.tour_image.length
                                ? card?.tour_image[0].src
                                : ""
                                }`}
                              alt="Featured Package"
                              onError={(e) => {
                                const target = e.target as HTMLImageElement;
                                if (target) {
                                  target.src = Noimg;
                                }
                              }}
                              className=" card-img-top-inter2 card-img2"
                            />

                            <div className="for_visitdays mb-4">
                              <h5 className="for_h5">
                                {card.tourtype === "normal"
                                  ? "Group"
                                  : card.tourtype}
                              </h5>
                            </div>
                            <div className="for-sold-out2 mb-4">
                              {card?.tagLabelNames[0] ? (
                                <>
                                  <img
                                    src={Label2Image}
                                    className="img-fluiddd"
                                  />
                                  <div className="LabelName">
                                    {card?.tagLabelNames[0]}
                                  </div>
                                </>
                              ) : null}
                            </div>

                            <div className="dayandnightw">
                              <p>
                                {card.nightorday}N/{card.nightorday + 1}D
                              </p>
                            </div>
                            <div className="card-body">
                              <div className="card-text">
                                <div className="main_para justify-content-between">
                                  <h5 className="loremforfeatured">
                                    {card.title}
                                  </h5>
                                  {card.location &&
                                    card.location !== "undefined" && (
                                      <p className="fetured_para">
                                        {
                                          card.location
                                          // .split(" ")
                                          // .slice(0, 4)
                                          // .join(" ")
                                        }
                                      </p>
                                    )}
                                </div>
                                <div className="d-flex justify-content-between align-items-baseline mt-2">
                                  {card.packageType_price &&
                                    card.packageType_price !== "undefined" && (
                                      <p className=" startrate ">
                                        {card.tourtype === "normal"
                                          ? `Start from ₹${card?.packageType_price}`
                                          : "Customizable Price"}
                                      </p>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* </Link> */}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

export default Featured;
